import React from 'react';

import axios from 'axios';

import _ from 'underscore';
import { showLoading, hideLoading } from '../Components/ModalComponent';
import IMComponent from '../Components/IMComponent';

import UsersGrid from './UsersGrid';
import { Pagination, Dimmer, Loader, Image, Segment, Message } from 'semantic-ui-react';

import matchSorter from 'match-sorter';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import applicationActionCreator, { actions } from '../../redux/actions/applicationActions';
import { Link } from 'react-router-dom';

class List extends IMComponent {
    constructor(props) {
        super(props);
        this.state = { data: props.data, usersToggleAdmin: [], pageNumber: 1, pageSize: 10, loading: false, totalPages: -1 };
        //this.loadUsersFromServer();
        this.handleSelected = this.handleSelected.bind(this);
        this.toggleAdministratorPermission = this.toggleAdministratorPermission.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.handlePageChanged = this.handlePageChanged.bind(this);
        this.loadUsersFromServer = this.loadUsersFromServer.bind(this);
        this.loadDataGrid = this.loadDataGrid.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
    }

    componentDidMount() {
        this.loadUsersFromServer();
    }

    handleEditUser = (id) => {
        this.props.history.push(`./EditUser/${id}`);
    }

    saveChanges() {
        this.props.showLoading('SUBMIT_USER_CHANGES');
        const self = this;
        return axios.put('/api/Users', this.state.usersToggleAdmin)
            .then(function (response) {
                if (response) {
                    self.loadUsersFromServer();
                    self.setState({ usersToggleAdmin: [] });
                    self.showToastSuccess({ title: "Users List", message: "The users list has been updated." })
                }
            })
            .catch(function (e) {
                console.error(self.props.match.url, e.status, e.toString());
                self.showToastError({ title: "Users List", message: e.response.data })
            })
            .then(function () {
                self.props.hideLoading('SUBMIT_USER_CHANGES');
            })
    }

    handleSelected(key, value) {
        //this.props.handleFilter(value);
    }

    toggleAdministratorPermission(e) {
        const users = this.state.data;
        var usersAdminChanged = this.state.usersToggleAdmin;
        var userId = e.target.id;
        if (usersAdminChanged.findIndex(u => u === userId) > -1) {
            usersAdminChanged = usersAdminChanged.filter(u => u !== userId);
        }
        else {
            usersAdminChanged.push(e.target.id);
        }
        var userIndex = users.findIndex(u => u.id.toString() === userId);
        users[userIndex].administrator = !users[userIndex].administrator;
        this.setState({ data: users, usersToggleAdmin: usersAdminChanged });
        //update status in server side
        return axios({
            method: 'PUT', url: '/api/ToggleUserAdmin', data: e.target.id, headers: { 'Content-Type': 'application/json; charset=utf-8' }
        });
    }


    handleLicenseUsageFilter(e) {
        e.persist()
        const licenseId = e.target.id;
        const userCount = e.target.getAttribute('usercount');
        const usedCount = e.target.getAttribute('usedcount');
        const requesturl = 'api/Licenses/' + licenseId + '/LicenseUsages';
        this.setState({ manage: false });
        const self = this;
        axios.get(requesturl)
            .then(function (response) {

                if (response) {
                    self.props.setUserList(response.data);
                    self.props.setLicenseId(licenseId);
                    self.props.setUserCount(userCount);
                    self.props.setUsedCount(usedCount);
                    self.props.detailType('LicenseUsage');
                    self.props.match.params.detailName = 'LicenseUsage';
                    self.props.history.push('./LicenseUsages');
                }
            })
            .catch(function (e) {
                console.error(e.status, e.toString());
            });
    }

    loadUsersFromServer() {
        const self = this;

        this.setState({ loading: true });
        this.handleFilterChange("")
            .then(function () {

                this.setState({ loading: false });
            })
            //return this.loadDataGrid(this.state.pageNumber, this.state.pageSize, "")
            .catch(function (e) {
                console.error(self.props.match.url, e.status, e.toString());
                self.setState({ loading: false });
            })
    }

    loadDataGrid = function (pageNumber, pageSize, criteria) {
        this.setState({ loading: true });
        const self = this;
        //const response = await axios.get('api/UsersPaged?pageNumber=' + pageNumber + "&pageSize=" + pageSize);
        return axios.get('api/SearchUserPaged', { params: { pageNumber, pageSize, criteria, version: Math.random() } })
            .then(function (response) {
                if (response) {
                    self.setState((prevState) => ({
                        ...prevState,
                        data: response.data, loading: false
                    }));
                }
            })
    }

    handlePageChanged = (event, data) => {
        this.setState({ pageNumber: data.activePage });
        //this.loadDataGrid(data.activePage, this.state.pageSize);
        this.loadDataGrid(data.activePage, this.state.pageSize, this.state.lastSearched);
    }

    handleFilterChange = function (queryName) {
        const self = this;
        return axios.get('api/SearchUserPagedCount?criteria=' + queryName)
            .then(function (count) {

                self.setState({ totalPages: Math.ceil(count.data / 10) });
                self.setState({ loading: true, pageNumber: 1, lastSearched: queryName, pageSize: 10 });
                //const response = await axios.get('/api/SearchUser?criteria=' + queryName + "&pageSize=" + this.state.pageSize);
                return axios.get('api/SearchUserPaged?pageNumber=' + 1 + "&pageSize=" + 10 + "&criteria=" + queryName);
            })
            .then(function (response) {

                if (!response) {
                    return;
                }
                let resultData = response.data;
                self.setState({ data: _.sortBy(resultData, x => x.firstName + x.lastName), loading: false });
            });
    }



    render() {
        return (
            <div>
                <a href='/Licenses' className="pull-right floated right" >
                    <svg className="special__icon">
                        <use xlinkHref="img/sprite/sprite.svg#svg--close-browser"></use>
                    </svg>
                </a>
                <h1 className="headline-primary">Users</h1>
                <button onClick={this.saveChanges} disabled={this.state.usersToggleAdmin.length === 0}
                    className={(this.state.usersToggleAdmin.length !== 0 ? 'blue' : 'gray') + " align right ui button"}>Save</button>
                    <Link className="blue ui button align right" to={`/user/new`} style={{ height: "35px" }}>New</Link>

                <div style={{ clear: "both" }}></div>
                <Segment style={{ paddingBottom: "50px" }}>
                    <Message info>
                        You can swith the role for user between 'IM Super Administrator'/'Standard User'.
                    </Message>
                    <UsersGrid data={this.state.data} handleEditUser={this.handleEditUser} toggleAdministratorPermission={this.toggleAdministratorPermission} onHandleFilterChange={this.handleFilterChange} />

                    <Pagination
                        defaultActivePage={1}
                        totalPages={this.state.totalPages} onPageChange={this.handlePageChanged} style={{ float: "left", marginLeft: "20px" }} />
                    <Loader active={this.state.loading} />
                </Segment>


            </div >
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        showLoading: function (caller) { return dispatch(applicationActionCreator.showLoading(caller)) },
        hideLoading: function (caller) { return dispatch(applicationActionCreator.hideLoading(caller)) },
        successMessage: function (message) { return dispatch(applicationActionCreator.notification.success(message)) }
    }
}

export default connect(null, mapDispatchToProps)(List);