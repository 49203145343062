import '@fortawesome/fontawesome-free';

import React from 'react';

import axios from 'axios';

import { Link, Route, withRouter } from 'react-router-dom';
import * as authenticateHttp from '../application/Services/authenticateHttp'
import { getCookieByName } from './utility/Helper';
import { bindActionCreators, compose } from 'redux';
import applicationActionCreator, { actions } from '../redux/actions/applicationActions';
import { connect } from 'react-redux';
import { useRef } from 'react'

class Login extends React.Component {
    constructor(props) {
        super(props);
        const { match: { params: { tokenExternalId, provider } = {} } = {} } = props;
        this.tokenExternalId = tokenExternalId;
        this.jwtZendeskFormRef = React.createRef();
        this.jwtZendeskInputRef = React.createRef();
        this.state = {
            loginFailed: false,
            userName: '',
            password: '',
            errorMessage: '',
            rememberPasswd: false,
            isEncodedPasswd: false,
            provider
        };
        this.handleUserChange = this.handleUserChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.onLoginFailed = this.onLoginFailed.bind(this);
        if (provider === "Zendesk") {
            this.handleLoginSubmit = this.handleLoginSubmitZendDesk.bind(this);
        }
        else {
            this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
        }
        this.handleLoginFailed = this.handleLoginFailed.bind(this);
        this.forgotPasswordHandleClick = this.forgotPasswordHandleClick.bind(this);
        this.handleRememberPasswd = this.handleRememberPasswd.bind(this);
        this.handlePasswdFieldFocus = this.handlePasswdFieldFocus.bind(this);
    }

    forgotPasswordHandleClick() {

    }
    handleUserChange(e) {
        this.setState({ userName: e.target.value });
    }
    handlePasswordChange(e) {
        this.setState({ password: e.target.value, isEncodedPasswd: false });
    }
    handleLoginFailed(errorMessage = '') {
        if (errorMessage === '')
            errorMessage = 'Sign in failed! Please check your credentials and try again.';

        this.setState({ loginFailed: true, errorMessage: errorMessage });
    }

    handleSuccessMessage(message = '', title = '') {
        this.setState({ success: { message, title } });
    }

    handleRememberPasswd(e) {
        this.setState({
            rememberPasswd: !this.state.rememberPasswd
        });
    }

    handlePasswdFieldFocus(e) {
        var passFromCookie = getCookieByName("encpssw_" + this.state.userName);
        if (passFromCookie && passFromCookie != null && passFromCookie != "") {
            this.setState({ isEncodedPasswd: true, password: passFromCookie });
        }

    }
    handleLoginSubmitZendDesk = function (e) {
        e.preventDefault();
        const authParams = { email: this.state.userName, password: this.state.password }
        const self = this;
        authenticateHttp.authenticateZendDesk(authParams)
            // https://informationmapping.atlassian.net/browse/FPC-78
            // https://support.zendesk.com/hc/en-us/articles/4408845838874-Enabling-JWT-single-sign-on?elqTrackId=4ac71663aba14c86b01f8fbecda963b8&elq=7b46cb2a87fd4b44a27d9a40e4207e4f&elqaid=83119&elqat=1&elqCampaignId=15145#topic_w5x_1fh_3fb
            // https://github.com/zendesk/zendesk_jwt_sso_examples/blob/master/form_submission/react_jwt.jsx
            .then(function (zendDeskUrl) {
                const arr = zendDeskUrl.split('=');
                const jwtUrl = arr[0];
                const searchParams = new URLSearchParams(zendDeskUrl);
                const jwt = searchParams.get(jwtUrl);

                self.jwtZendeskInputRef.current.value = jwt;
                self.jwtZendeskFormRef.current.submit();

                //window.location.href = zendDeskUrl;
            })
            .catch(self.onLoginFailed);
    }

    handleLoginSubmit = function (e) {
        this.setState({ success: null });
        e.preventDefault();
        this.props.showLoading("SubmitLogin");
        const self = this;
        let promise = null;
        if (this.tokenExternalId) {
            promise = axios.post(`/api/ExternalAuthenticate/${this.tokenExternalId}`, { Email: this.state.userName, Password: this.state.password })
                .then(function (response) {
                    self.handleSuccessMessage(response.headers.location, "Redirecting...");
                    self.setState({ redirectUrl: response.headers.location });
                })
        }
        else {
            promise = axios.post('/api/Authenticate', { Email: this.state.userName, Password: this.state.password, RememberPassword: this.state.rememberPasswd, IsEncodedPassword: this.state.isEncodedPasswd })
                .then(function () {
                    self.props.onUserChange();
                    self.props.onUserLogined();
                });
        }
        promise
            .catch(self.onLoginFailed)
            .then(function () {
                self.props.hideLoading("SubmitLogin");
            });
    }

    onLoginFailed = function (ex) {
        let errorMessage = '';
        const self = this;
        if (ex && ex.response && ex.response.status === 423) {
            errorMessage = ex.response.data;
            if (errorMessage.includes('Password had expired'))
                return axios.post('/api/ForgotPassword', { Email: self.state.userName })
                    .then(function (login) {
                        if (login)
                            self.setState({ userName: '', password: '' });
                    })
                    .catch(function (e) {
                    })
                    .then(function () {
                        self.handleLoginFailed(errorMessage);
                    });
        } else {
            self.handleLoginFailed(errorMessage);
        }
    }


    render() {
        const { success, redirectUrl, provider } = this.state;
        const subdomain = 'support.informationmapping.com';
        // The subdomain support is actually a CNAME of the other one.
        // CNAME    support    informationmapping.zendesk.com

        if (redirectUrl)
            return <Route component={() => {
                window.location.href = redirectUrl;
                return null;
            }} />
        return (
            <div>
                <div className="grid">
                    <header className="grid__col--12 panel--padded--centered" role="banner">
                        <a className="site-logo left" href="https://www.informationmapping.com/">
                            <b className="srt">Information Mapping Logo</b>
                        </a>
                        <h1 className="headline-primary--grouped right"><a className="site-logo-trial" href="/">FS Pro Console</a></h1>
                    </header>
                </div>
                <div className="grid">
                    <div className="centered grid__col--8">
                        <h3 style={{ marginBottom: "8px", color: "white" }} className="headline-secondary--centered">Welcome to the FS Pro Console. Here you can manage your licenses.</h3>
                    </div>
                </div>
                <div className="grid padded">
                    <div id="getemail" className="centered grid__col--5 panel__inverse--padded">
                        {(this.state.loginFailed) ?
                            <div className="ui negative message">
                                <div className="header">Login Error</div>
                                <p>{this.state.errorMessage}</p>
                            </div> : ''}
                        {(success) ?
                            <div className="ui positive message">
                                <div className="header">{success.title}</div>
                                <p>{success.message}</p>
                            </div> : ''}
                        <h1 className="headline-secondary--centered">Log In</h1>
                        <form className="form" onSubmit={this.handleLoginSubmit}>
                            <div className="form__group">
                                <label className="form__label--hidden" htmlFor="email">Email address:</label>
                                <input
                                    className="form__input"
                                    type="email"
                                    placeholder="Email address"
                                    value={this.state.userName}
                                    onChange={this.handleUserChange}
                                    required
                                />
                                <label className="form__label--hidden" htmlFor="password">Password:</label>
                                <input
                                    className="form__input"
                                    type="password"
                                    placeholder="Password"
                                    value={this.state.password}
                                    onChange={this.handlePasswordChange} onFocus={this.handlePasswdFieldFocus}
                                    required
                                    style={{ marginBottom: "5px" }}
                                />
                                <div id="linkContainer">
                                    <div>
                                        <label htmlFor="chkRememberPasswd" style={{ fontSize: "14px" }}>
                                            <input type="checkbox"
                                                defaultChecked={this.state.rememberPasswd}
                                                ref="chkRememberPasswd"
                                                onChange={this.handleRememberPasswd} id="chkRememberPasswd"
                                                className="checkbox-button__input" />
                                            <span className="checkbox-button__control"></span>
                                            <span class="checkbox-button__label">Remember Password</span>
                                        </label><i className="fas fa-info-circle" style={{ paddingLeft: '3px' }}
                                            title="If you check this box, your password will be stored in this browser."></i></div>
                                    <div className="loginRightLink"> <Link className="loginForgotPasswdLink" to='/ForgotPassword' style={{ color: "#4c5a61" }} onClick={this.forgotPasswordHandleClick}>Forgot Password?</Link></div>
                                </div>

                                <input className="primary ui button fluid form__btn" type="submit" value="Log in" style={{ marginTop: "5px", width: '90px' }} />

                            </div>

                        </form>

                        {/* The payload must be base64-encoded and submitted via a form submission from a client.
                            Submitting the payload with a client-side AJAX, fetch, or axios request won't work because the request will be blocked by the client's same-origin policy.*/}
                        <form ref={this.jwtZendeskFormRef} action={`https://${subdomain}/access/jwt`} method="post">
                            <input ref={this.jwtZendeskInputRef} type="hidden" name="jwt"></input>
                        </form>

                    </div>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return {
        showLoading: function (caller) { return dispatch(applicationActionCreator.showLoading(caller)) },
        hideLoading: function (caller) { return dispatch(applicationActionCreator.hideLoading(caller)) }
    }
}
export default withRouter(connect(null, mapDispatchToProps)(Login));
