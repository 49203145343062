import React, { } from 'react'
import Axios from 'axios';

import moment from 'moment';

import { Link } from 'react-router-dom';

import { Form, Button, Icon, Modal } from 'semantic-ui-react';

import { DateInput } from 'semantic-ui-calendar-react';

import { goToPageNavigation } from '../Index';
import IMComponent from '../Components/IMComponent';
import { IMErrorComponent } from '../Components/IMErrorComponent';
import applicationActionCreator from '../../redux/actions/applicationActions';
import { isEmailValid, IsValidDate, dateFormat, stringToDate } from '../../components/utility/Helper';
import { connect } from 'react-redux';


class Edit extends IMComponent {

    constructor(props) {
        super(props, {
            startDate: moment(new Date()).format(dateFormat),
            endDate: moment(new Date().setFullYear(new Date().getFullYear() + 1)).format(dateFormat),
            name: "",
            encryptedProductId: "",
            userCount: 1,
            sharepointIntegration: false,
            showRenewMsg: false,
            renewUserCount: 0,
            currentUserCount: 0,
            aiFeaturesEnabled: false
        });
        this.loadProducts();
        this.loadEditInformation();
        this.submitHandler = this.submitHandler.bind(this);
        this.onProductsLoad = this.onProductsLoad.bind(this);
        this.onModelValidation = this.onModelValidation.bind(this);
        this.loadProducts = this.loadProducts.bind(this);
        this.checkChange = this.checkChange.bind(this);
        this.deleteLicense = this.deleteLicense.bind(this);
        this.renewLicense = this.renewLicense.bind(this);
        this.checkAdvancedTemplateChange = this.checkAdvancedTemplateChange.bind(this);
        this.checkAIFeaturesEnabledChange = this.checkAIFeaturesEnabledChange.bind(this);
        this.convertStringToInt = this.convertStringToInt.bind(this);
    };

    componentWillMount = function () {

        this.setState({ productList: [] });
    }

    convertStringToInt = (obj, propertyName) => {
        var property = obj[propertyName];
        if (typeof (property) === "string") {
            obj[propertyName] = parseInt(property);
        }
    }

    updateProducts = (products) => {
        this.setState({ productList: products });
        if (products.length > 0)
            this.onModelPropertyChange({
                target: {
                    name: "encryptedProductId",
                    value: products[0].value
                }
            });
    }

    onProductsLoad = function () {
        const products = this.state.productList;
        if (products.length > 0)
            this.onModelPropertyChange({
                target: {
                    name: "encryptedProductId",
                    value: products[0].value
                }
            });
    }

    loadProducts() {
        const self = this;
        return Axios.get('/api/Product')
            .then(function (response) {
                const productOptions = response.data.map(function (item) {
                    return { key: item.productId + '', value: item.productId, text: item.name };
                })
                    .filter(x => x.text.includes("FS Pro"));
                self.updateProducts(productOptions);
            })
            .catch(function (e) {
                console.error(self.props.match.url, e.status, e.toString());

            })
    }

    loadEditInformation() {
        if (this.props.match.params.encryptedLicenseId) {
            const self = this;
            return Axios.get('/api/Licenses/encrypted/' + this.props.match.params.encryptedLicenseId)
                .then(function (response) {
                    self.setState({
                        model: {
                            userCount: response.data.userCount,
                            name: response.data.manager,
                            startDate: response.data.startDate,
                            endDate: response.data.endDate,
                            productId: response.data.productId,
                            encryptedProductId: response.data.encryptedProductId,
                            sharepointIntegration: response.data.sharePointIntegration,
                            advancedTemplate: response.data.advancedTemplate,
                            aiFeaturesEnabled: response.data.aiFeaturesEnabled,
                        }
                    });
                    self.setState({ errors: {} });
                })
        }
    }

    submitHandler = function (e, d) {
        const self = this;
        e.preventDefault()
        this.props.showLoading('SUBMIT_LICENCE');
        const title = 'License' + (self.props.match.params.encryptedLicenseId) ? 'Update' : 'Create';
        let promise = null;
        this.convertStringToInt(self.state.model, 'userCount');
        if (self.props.match.params.encryptedLicenseId) {
            const url = '/api/licenses/' + self.props.match.params.encryptedLicenseId;

            promise = Axios.put(url, self.state.model)
                .then(function (response) {
                    const urlUpdateToken = '/api/Tokens/Update/' + response.data.encryptedId;
                    Axios.put(urlUpdateToken)
                        .then(function () {
                            self.showToastSuccess({ title: "License Updated", message: "License was updated successfully." });
                            goToPageNavigation("/")
                        })
                        .catch(function (ex) {
                            if (!!ex.response && ex.response.status === 400) {
                                self.showToastError({ title: title, message: ex.response.data });
                            }
                            else {
                                self.showToastError({ title: title, message: "There were some error please contact to Administrators." })
                            }
                        });
                });
        } else {
            const url = '/api/licenses';
            promise = Axios.post(url, self.state.model)
                .then(function(response) {
                    const urlCreateToken = '/api/Tokens/Create/' + response.data.encryptedId;
                    Axios.post(urlCreateToken)
                        .then(function() {
                            self.resetModel();
                            self.onProductsLoad();
                            self.showToastSuccess({
                                title: "License Created",
                                message: "License was created successfully."
                            });
                        })
                        .catch(function(ex) {
                            if (!!ex.response && ex.response.status === 400) {
                                self.showToastError({ title: title, message: ex.response.data });
                            } else {
                                self.showToastError({
                                    title: title,
                                    message: "There were some error please contact to Administrators."
                                })
                            }
                        });
                });
        }
        return promise
            .catch(function (ex) {
                if (!!ex.response && ex.response.status === 400) {
                    self.showToastError({ title: title, message: ex.response.data });
                }
                else {
                    self.showToastError({ title: title, message: "There were some error please contact to Administrators." })
                }
            })
            .then(function () {
                self.props.hideLoading('SUBMIT_LICENCE');
            })
    }

    onModelValidation = function () {
        const { model: { name, startDate, endDate } } = this.state;
        let valid = true;
        let errors = {};
        if (!isEmailValid(name)) {
            errors.email = "Email is not valid.";
            valid = false;
        }
        if (!IsValidDate(startDate)) {
            errors.startDate = "Start Date is not valid.";
            valid = false;
        }
        if (!IsValidDate(endDate)) {
            errors.endDate = "End date is not valid.";
            valid = false;
        }
        if (stringToDate(startDate) > stringToDate(endDate)) {
            errors.startDate = "Start Date should not be greater than End Date.";
            valid = false;
        }
        this.setState({ errors });
        return valid;
    }

    checkChange = function (e) {
        let model = this.state.model;
        model['sharepointIntegration'] = !model['sharepointIntegration'];
        this.setState({ model });
    }

    checkAdvancedTemplateChange = function (e) {
        let model = this.state.model;
        model['advancedTemplate'] = !model['advancedTemplate'];
        this.setState({ model });
    }
    checkAIFeaturesEnabledChange = function (e) {
        let model = this.state.model;
        model['aiFeaturesEnabled'] = !model['aiFeaturesEnabled'];
        this.setState({ model });
    }

    deleteLicense = function (e) {
        e.preventDefault();
        this.props.showLoading('DELETE_LICENSE');
        const self = this;
        const url = '/api/licenses/' + self.props.match.params.encryptedLicenseId;
        this.convertStringToInt(self.state.model, 'userCount');
        return Axios.delete(url, self.state.model)
            .then(function () {
                self.showToastSuccess({ title: "License deleted", message: "License was deleted successfully." });
            })
            .catch(function (ex) {
                if (!!ex.response && ex.response.status === 400)
                    self.showToastError({ title: "License deleted", message: ex.response.data })

                else self.showToastError({ title: "License delete", message: "There were some error please contact to Administrators." })

            })
            .then(function () {
                self.props.hideLoading('DELETE_LICENSE');
                self.props.history.push('/Licenses');
            })
    }

    renewLicense = e => {
        const self = this;
        e.preventDefault();
        const title = 'License Renew';
        const url = '/api/licenses/Renew/' + self.props.match.params.encryptedLicenseId;
        this.convertStringToInt(self.state.model, 'userCount');
        return Axios.post(url, self.state.model)
            .then(function (response) {
                if (response) {
                    self.resetModel();
                    self.onProductsLoad();
                    self.showToastSuccess({ title: "License Renewed", message: "License was renewed successfully." });
                    self.props.history.push('/Licenses');
                }
            })
            .catch(function (ex) {
                if (!!ex.response && ex.response.status === 400 && !!ex.response.data.renewUserCount && !!ex.response.data.currentUserCount) {
                    self.setState({ showRenewMsg: true, renewUserCount: ex.response.data.renewUserCount, currentUserCount: ex.response.data.currentUserCount });
                }
                else if (!!ex.response && ex.response.status === 400) {
                    self.showToastError({ title: title, message: ex.response.data });
                }
                else {
                    self.showToastError({ title: title, message: "There were some error please contact to Administrators." })
                }
            })
            .then(function () {
                self.props.hideLoading('SUBMIT_LICENCE');
            });
    }

    initializeProductFeatures = d => {
        let feature = d.options.filter(x => x.key === d.value);
        if (feature.length === 1) {
            let model = this.state.model;
            switch (feature[0].text) {
                case "FS Pro":
                    model['sharepointIntegration'] = false;
                    model['advancedTemplate'] = false;
                    model['aiFeaturesEnabled'] = false;
                    this.setState({ model });

                    this.setState({
                        featureDisabled: false
                    })
                    break;
                case "FS Pro For Outlook":
                    model['sharepointIntegration'] = false;
                    model['advancedTemplate'] = false;
                    model['aiFeaturesEnabled'] = false;
                    this.setState({ model });

                    this.setState({
                        featureDisabled: true
                    })
                    break;
                case "FS Pro Business":
                    model['sharepointIntegration'] = true;
                    model['advancedTemplate'] = true;
                    model['aiFeaturesEnabled'] = true;
                    this.setState({ model });

                    this.setState({
                        featureDisabled: false
                    })
                    break;
                default:
            }
        }
    }

    initializeEndDate = d => {
        let model = this.state.model;
        let startDate = new Date(d['value']);
        model['endDate'] = moment(startDate.setFullYear(startDate.getFullYear() + 1)).format(dateFormat);

        this.setState({ model });
    }

    render() {
        const closeStyleButton = {}
        const { productList, model: { name, encryptedProductId, startDate, endDate, userCount, sharepointIntegration, advancedTemplate, aiFeaturesEnabled }, validModel, featureDisabled,
            errors: { email: errorEmail,
                startDate: errorStartDate,
                endDate: errorEndDate } } = this.state;
        const classColumn = 'ui im_text_field';
        return (
            <div className="no__padding">
                <div className="floated right align">
                    <Link to='/Licenses' className="pull-right float right" style={closeStyleButton}>
                        <Icon name='close' />
                    </Link>
                </div>
                <h1 className="headline-primary">Licenses</h1>
                <h3 className="headline-tertiary center">{(this.props.match.params.encryptedLicenseId) ? 'Update License' : 'Create License'}</h3>
                <div className='ui grid' style={{ width: '100%' }}>
                    <Form className="centered six wide column"
                        onSubmit={(e, d) => this.submitHandler(e, d)}>
                        <IMErrorComponent error={errorEmail} position='right center'>
                            <Form.Input required label='Email' name="name" onChange={this.onModelPropertyChange}
                                error={errorEmail != null}
                                className={classColumn} placeholder="Email" value={name} ></Form.Input>
                        </IMErrorComponent>

                        <Form.Select required className={classColumn} options={productList} label='Product'
                            name="encryptedProductId" value={encryptedProductId} onChange={(e, d) => { this.onModelPropertyChange({ target: d }); this.initializeProductFeatures(d); }}
                        ></Form.Select>
                        <DateInput required className={classColumn}
                            name="startDate" label="Start Date"
                            placeholder="mm/dd/yyyy"
                            dateFormat={dateFormat}
                            closable
                            duration={200}
                            initialDate={new Date()}
                            value={startDate}
                            iconPosition="left"
                            onChange={(e, d) => { this.onModelPropertyChange({ target: d }); this.initializeEndDate(d); }}></DateInput>
                        {this.props.currentUser.data.administrator ?
                            <DateInput required
                                className={classColumn}
                                name="endDate" label="End Date"
                                placeholder="mm/dd/yyyy"
                                dateFormat={dateFormat}
                                closable
                                disabled={false}
                                duration={200}
                                initialDate={startDate}
                                value={endDate}
                                iconPosition="left"
                                onChange={(e, d) => this.props.currentUser.data.administrator && this.onModelPropertyChange({ target: d })}
                            /> :
                            <div className="field ui im_text_field">
                                <label>End Date</label>
                                <div className="ui left icon input">
                                    <i aria-hidden="true" className="calendar icon"></i>
                                    <input name="endDate" type="text" value={startDate.toString("mm/dd/yyyy")} disabled />
                                </div>
                            </div>}
                        <Form.Input required label='Number of Licenses' name="userCount" onChange={this.onModelPropertyChange} min='1'
                            className={classColumn} placeholder="" value={userCount} type="Number" ></Form.Input>
                        <Form.Checkbox label="SharePoint Integration" checked={sharepointIntegration} name="sharepointIntegration" onChange={this.checkChange} disabled={featureDisabled}></Form.Checkbox>
                        <Form.Checkbox label="Advanced Template" checked={advancedTemplate} name="advancedTemplate" onChange={this.checkAdvancedTemplateChange} disabled={featureDisabled}></Form.Checkbox>
                        <Form.Checkbox label="AI Features" checked={aiFeaturesEnabled} name="aiFeaturesEnabled" onChange={this.checkAIFeaturesEnabledChange} disabled={featureDisabled}></Form.Checkbox>

                        {(this.props.match.params.encryptedLicenseId) ?
                            <div className="center">
                                <Form.Button size="large" primary content="Update" floated="left"></Form.Button>
                                <Button size="large" secondary onClick={this.deleteLicense} floated="left">Delete</Button>
                                <Button size="large" primary onClick={this.renewLicense} floated="left">Renew</Button>
                            </div> :
                            <Form.Button size="large" className="center" primary content="Create" floated="left" disabled={!validModel}></Form.Button>}

                    </Form>
                </div>
                <Modal size="tiny" open={this.state.showRenewMsg} onClose={() => { this.setState({ showRenewMsg: false }); }}>
                    <Modal.Header>License Renew</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <div>
                                <p>
                                    <span style={{ color: '#FF8019' }}>Error:</span> License Renewal Unsuccessful<br />
                                    Current License Pack: <span style={{ color: '#FF8019' }}>{this.state.currentUserCount}</span> Users<br />
                                    Requested Renewal: <span style={{ color: '#FF8019' }}>{this.state.renewUserCount}</span> Users<br />
                                    Issue: The number of currently assigned users exceeds the requested renewal count. Please reduce the assigned users to <span style={{ color: '#FF8019' }}>{this.state.renewUserCount}</span> <span style={{ color: '#FDC030' }}>or</span> fewer before proceeding with the renewal.<br />
                                </p>
                                <p style={{ textAlign: 'right', 'font-style': 'italic' }}>
                                    (Click outside this dialog to close it).
                                </p>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        currentUser: state.application.currentUser
    }
}
function mapDispatchToProps(dispatch) {
    return {
        showLoading: function (caller) { return dispatch(applicationActionCreator.showLoading(caller)) },
        hideLoading: function (caller) { return dispatch(applicationActionCreator.hideLoading(caller)) },
        successMessage: function (message) { return dispatch(applicationActionCreator.notification.success(message)) },
        errorMessage: function (message) { return dispatch(applicationActionCreator.notification.error(message)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Edit)