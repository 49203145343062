import React from "react";

import { Button, Dropdown, Input, Grid } from "semantic-ui-react";

import IMComponent from "../Components/IMComponent";
import { IMErrorComponent } from "../Components/IMErrorComponent";
import { isEmailValid, messages } from "../../components/utility/Helper";

import { addNewUsage } from "./List";

export default class LicenseUsagesNewRow extends IMComponent {
  constructor(props) {
    super(props, { email: "", firstName: "", lastName: "", role: "" });
    const { canShowRoles, roles } = this.props;
    if (canShowRoles) {
      const roleList = 
         roles.map((x) => {
            return { text: x.descr, value: x.id };
          });
      const roleSelected = roleList[1].value;
      this.onModelPropertyChange({
        target: { name: "role", value: roleSelected },
      });
    }
  }

  handleAddLicenseUser = () => {
    let newLicenseUsage = {
      accountName: this.state.model.email,
      encryptedRoleId: this.state.model.role,
      firstName: this.state.model.firstName,
      lastName: this.state.model.lastName,
      roleId: this.state.model.role ? this.state.model.role : 0,
    };
    addNewUsage(newLicenseUsage);
    this.resetModel();
  };
  onModelValidation = () => {
    const { email } = this.state.model;
    let errors = {};
    let validModel = true;
    if (!isEmailValid(email)) {
      errors.email = messages.errors.invalidEmail;
      validModel = false;
    }
    this.setState({ errors });

    return validModel;
  };
    render() {
        /*Set role id to publisher as default */
        if (this.state.model.role === "") {
            this.state.model.role = 3;
        }

    const { email, firstName, lastName, role } = this.state.model;
    const { canShowRoles, roles } = this.props;
    const { validModel } = this.state;
    const { email: errorEmail } = this.state.errors;
    const roleList = canShowRoles
      ? roles.map((x) => {
          return { text: x.descr, value: x.id };
        })
      : null;
    const roleSelected = canShowRoles && !role ? roleList[0].value : role;
    const extraColumn = canShowRoles ? 1 : 3;
    return (
      <Grid.Row>
        <Grid.Column width={2 + extraColumn}>
          <IMErrorComponent error={errorEmail} position="bottom center">
            <Input
              name="email"
              error={!!errorEmail}
              type="text"
              className="form-input"
              fluid
              value={email}
              onChange={this.onModelPropertyChange}
            />
          </IMErrorComponent>
        </Grid.Column>
        {canShowRoles ? (
          <Grid.Column width={2}>
            <Dropdown
              value={roleSelected}
              options={roleList}
              onChange={(e, d) => this.onModelPropertyChange({ target: d })}
              name="role"
            />
          </Grid.Column>
            ) : null}
        {canShowRoles ? (
            <Grid.Column width={2}>
            </Grid.Column>
        ) : null}
        <Grid.Column width={2}>
          <Input
            name="firstName"
            type="text"
            className="form-input"
            value={firstName}
            fluid
            onChange={this.onModelPropertyChange}
          />
        </Grid.Column>
        <Grid.Column width={2}>
          <Input
            name="lastName"
            type="text"
            className="form-input"
            value={lastName}
            fluid
            onChange={this.onModelPropertyChange}
          />
        </Grid.Column>
        <Grid.Column width={2}></Grid.Column>
        {canShowRoles ? null : <Grid.Column width={2}></Grid.Column>}

        {canShowRoles
            ? <Grid.Column width={3}>
                  <Button
                    fluid
                    primary
                    size="medium"
                    disabled={!validModel}
                    content="Add User"
                    className="two wide column"
                    style={{ width: '192px' }}
                    onClick={this.handleAddLicenseUser}
                  />
            </Grid.Column>
            : <Grid.Column width={3} style={{ paddingLeft: "5px" }}>
                <Button
                fluid
                primary
                size="medium"
                disabled={!validModel}
                content="Add User"
                className="two wide column"
                style={{ width: '192px' }}
                onClick={this.handleAddLicenseUser}
                    />
                </Grid.Column>
            }
      </Grid.Row>
    );
  }
}
