import React, { Component } from 'react'
import Axios from 'axios';

import { Button, Form, Grid, Dropdown, Menu, DropdownMenu, DropdownItem, Icon, Segment } from 'semantic-ui-react';

import { DateInput } from 'semantic-ui-calendar-react';

import { connect } from 'react-redux';

import { Confirm, Modal, Header, Image } from 'semantic-ui-react';

import IMComponent from '../Components/IMComponent';
import applicationActionCreator, { actions } from '../../redux/actions/applicationActions';
import SelectFile from '../../components/SelectFile';
import { dateFormat } from '../../components/utility/Helper';

class LicenseUsagesHeader extends IMComponent {
    constructor(props) {
        super(props);
        this.handleAdminEmailChange = this.handleAdminEmailChange.bind(this);
        this.handleOrganizationNameChange = this.handleOrganizationNameChange.bind(this);
        this.handleOrganizationChange = this.handleOrganizationChange.bind(this);
        this.changeLicenseAdmin = this.changeLicenseAdmin.bind(this);
        this.changeOrganizationName = this.changeOrganizationName.bind(this);
        this.changeOrganization = this.changeOrganization.bind(this);
        this.handleLinkOrganization = this.handleLinkOrganization.bind(this);
        this.handleLinkSelectedOrganization = this.handleLinkSelectedOrganization.bind(this);
        this.submitUpdateLifetimeHandler = this.submitUpdateLifetimeHandler.bind(this);
        this.onSelectionFileSuccess = this.onSelectionFileSuccess.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleAction = this.handleAction.bind(this);
        this.getFsProCloudSettingsOptions = this.getFsProCloudSettingsOptions.bind(this);
        this.showLicenseAdministratorHistory = this.showLicenseAdministratorHistory.bind(this);
        this.renderLicenseAdministratorHistoryData = this.renderLicenseAdministratorHistoryData.bind(this);
        this.toogleAIFeatures = this.toogleAIFeatures.bind(this);
        this.showLicenseManagemenHandler = this.showLicenseManagemenHandler.bind(this);
        this.showFSProCloudSettingsHandler = this.showFSProCloudSettingsHandler.bind(this);
        this.showUserDataOperationsHandler = this.showUserDataOperationsHandler.bind(this);
        this.showAIFeaturesHandler = this.showAIFeaturesHandler.bind(this);
        let { startDate, endDate } = props;
        this.state = {
            model: { startDate, endDate },
            licenseId: { licenseId },
            editManager: false, adminEmail: "", showConfirmModal: false, showMailSentMsg: false, isValidEmail: true, originalAdminEmail: "",
            canManage: false,
            organizationName: "",
            originalOrganizationName: "",
            editOrganizationName: false,
            editOrganization: false,
            linkOrganization: false,
            selectLicenseForLinking: false,
            showChangeOrganizationNameConfirmModal: false,
            showChangeOrganizationConfirmModal: false,
            showLinkOrganizationConfirmModal: false,
            showLicenseAdministratorHistory: false,
            organizations: [],
            organizationId: "",
            originalOrganizationId: "",
            importUsersRef: null,
            exportUsersRef: null,
            renameOrganizationRef: null,
            changeOrganizationRef: null,
            linkOrganizationRef: null,
            licenseAdministratorHistory: [],
            purchaserEmail: "",
            aiFeaturesEnabled: false,
            aiGatewayTokenId: null,
            aiGatewayInputTokens: 0,
            aiGatewayOutputTokens: 0,
            aiGatewayLimitTokens: 0,
            aiFeaturesDisabledBySuperAdmin: false,
            showLicenseManagement: false,
            showFSProCloudSettings: false,
            showUserDataOperations: false,
            showAIFeatures: false,
        }
        const { licenseId } = props;
        let managerId, organizationId;
        const self = this;
        Axios.get(`/api/Licenses/encrypted/${licenseId}`, { params: { version: Math.random() } })
            .then(function (response) {
                if (response) {
                    self.setState({
                        adminEmail: response.data.manager,
                        originalAdminEmail: response.data.manager,
                        canManage: response.data.canManage,
                        organizationName: response.data.organizationName,
                        originalOrganizationName: response.data.organizationName,
                        productId: response.data.productId,
                        licenseAdministratorHistory: response.data.licenseAdministratorHistory,
                        purchaserEmail: response.data.purchaserEmail,
                        aiFeaturesEnabled: response.data.aiFeaturesEnabled,
                        aiGatewayTokenId: response.data.aiGatewayTokenId,
                        aiGatewayInputTokens: response.data.aiGatewayInputTokens,
                        aiGatewayOutputTokens: response.data.aiGatewayOutputTokens,
                        aiGatewayLimitTokens: response.data.aiGatewayLimitTokens,
                        aiFeaturesDisabledBySuperAdmin: response.data.aiFeaturesDisabledBySuperAdmin,
                    });
                    managerId = response.data.encryptedManagerId;
                    organizationId = response.data.encryptedOrganizationId;
                }
            }).then(function () {
                Axios.get(`/api/Licenses/encryptedManagerId/${managerId}`, { params: { version: Math.random() } })
                    .then(function (response) {
                        if (response) {
                            self.setState({
                                organizations: response.data.map(function (l) { return { organizationId: l.encryptedOrganizationId, organizationName: l.organizationName } }),
                                organizationId: response.data.find(l => l.encryptedOrganizationId === organizationId).encryptedOrganizationId,
                                originalOrganizationId: response.data.find(l => l.encryptedOrganizationId === organizationId).encryptedOrganizationId
                            });
                        }
                    });
            });
    }

    toggleEditManager = () => {
        this.setState({ editManager: !this.state.editManager });
    }

    toggleChangeOrganizationName = () => {
        this.setState({ editOrganizationName: !this.state.editOrganizationName });
    }

    toggleLinkOrganization = () => {
        this.setState({ linkOrganization: !this.state.linkOrganization });
    }

    toggleChangeOrganization = () => {
        this.setState({ editOrganization: !this.state.editOrganization });
    }

    toggleEditManager = () => {
        this.setState({ editManager: !this.state.editManager });
    }

    changeLicenseAdmin = function () {
        //api/Licenses/switchAdmin
        const self = this;
        if (this.state.isValidEmail && this.state.adminEmail !== this.state.originalAdminEmail) {
            return Axios.post(`/api/Licenses/switchAdmin`, { LicenseId: this.props.licenseId, newAdminEmail: this.state.adminEmail })
                .then(function (response) {
                    if (response) {
                        self.setState({ showConfirmModal: false, showMailSentMsg: !self.props.isAdmin });
                        if (self.props.isAdmin) {
                            self.onCancel();
                            self.showToastSuccess({ title: "Switch Administrator License", message: "The license administrator was successfully changed." });

                        }
                    }
                })
        } else {
            self.showToastError({ title: "", message: "Please specify a valid email address different to the current admin license." });
            self.setState({ showConfirmModal: false })
        }
    }

    changeOrganizationName = function () {
        //api/Licenses/changeOrganizationName
        this.props.showLoading('CHANGE_ORGANIZATION_NAME');
        const self = this;
        const title = "Change Organization Name License";
        let message;
        return Axios.post(`/api/Licenses/changeOrganizationName`,
            { LicenseId: this.props.licenseId, NewOrganizationName: this.state.organizationName })
            .then(function (response) {
                if (response) {
                    self.setState({
                        showChangeOrganizationNameConfirmModal: false
                    });
                    self.props.reloadData();
                    if (self.props.isAdmin) {
                        self.onCancelChangeOrganizationName();
                        message = response.data ? response.data : "The license organization name was successfully changed.";
                        self.showToastSuccess({
                            title: title,
                            message: message
                        });
                    }
                }
            })
            .catch(function (ex) {
                if (!!ex.response && ex.response.status === 400) {
                    self.showToastError({ title: title, message: ex.response.data });
                }
                else {
                    self.showToastError({
                        title: title,
                        message: "There were some error please contact to Administrators."
                    });
                }
            })
            .then(function () {
                self.props.hideLoading('CHANGE_ORGANIZATION_NAME');
            });
    }

    changeOrganization = function () {
        //api/Licenses/changeOrganization
        this.props.showLoading('CHANGE_ORGANIZATION');
        const self = this;
        const title = "Change Organization License";
        return Axios.post(`/api/Licenses/changeOrganization`, { LicenseId: this.props.licenseId, NewOrganizationId: this.state.organizationId })
            .then(function (response) {
                if (response) {
                    self.setState({
                        showChangeOrganizationConfirmModal: false
                    });
                    self.props.reloadData();
                    if (self.props.isAdmin) {
                        self.onCancel();
                        self.showToastSuccess({
                            title: title,
                            message: "The license organization was successfully changed."
                        });

                    }
                }
            })
            .catch(function (ex) {
                if (!!ex.response && ex.response.status === 400) {
                    if (ex.response.data.status === 401) {
                        self.showToastError({ title: title, message: ex.response.data.message });
                    } else {
                        self.showToastError({ title: title, message: ex.response.data });
                    }
                }
                else {
                    self.showToastError({
                        title: title,
                        message: "There were some error please contact to Administrators."
                    });
                }
            })
            .then(function () {
                self.props.hideLoading('CHANGE_ORGANIZATION');
            });
    }

    handleLinkOrganization = function () {
        //api/Licenses/linkOrganization
        this.props.showLoading('LINK_ORGANIZATION');
        const self = this;
        const title = "Link Organization License";

        return Axios.post(`/api/Licenses/GetOrganizationsForLinking/${this.state.organizationName}/${this.props.licenseId}`)
            .then(function (response) {
                if (response) {
                    self.setState({
                        selectLicenseForLinking: true,
                        organizations: response.data.map(function (l) { return { organizationId: l.organizationId, organizationName: l.organizationName } }),
                        organizationId: response.data[0].organizationId
                    });
                }
            })
            .catch(function (ex) {
                if (!!ex.response && ex.response.status === 400) {
                    self.showToastError({ title: title, message: ex.response.data });
                }
                else {
                    self.showToastError({
                        title: title,
                        message: "There were some error please contact to Administrators."
                    });
                }
            })
            .then(function () {
                self.props.hideLoading('LINK_ORGANIZATION');
            });
    }

    handleLinkSelectedOrganization = function () {
        //api/Licenses/linkSelectedOrganization
        this.props.showLoading('LINK_SELECTED_ORGANIZATION');
        const self = this;
        const title = "Link Organization License";

        return Axios.post(`/api/Licenses/linkSelectedOrganization`, { EncryptedLicenseId: this.props.licenseId, OrganizationId: this.state.organizationId, OrganizationName: this.state.organizationName })
            .then(function (response) {
                if (response) {
                    self.setState({
                        linkOrganization: false,
                        selectLicenseForLinking: false,
                        showLinkOrganizationConfirmModal: false
                    });
                    self.props.reloadData();
                    if (self.props.isAdmin) {
                        self.onCancel();
                        self.showToastSuccess({
                            title: title,
                            message: "The license organization was successfully linked."
                        });
                    }
                }
            })
            .catch(function (ex) {
                if (!!ex.response && ex.response.status === 400) {
                    self.showToastError({ title: title, message: ex.response.data });
                }
                else {
                    self.showToastError({
                        title: title,
                        message: "There were some error please contact to Administrators."
                    });
                }
            })
            .then(function () {
                self.props.hideLoading('LINK_SELECTED_ORGANIZATION');
            });
    }

    isValidEmail = (email) => {
        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return reg.test(email);

    }


    handleAdminEmailChange = (e) => {
        this.setState({ isValidEmail: this.isValidEmail(e.target.value) });
        this.setState({
            adminEmail: e.target.value
        });
    }

    handleOrganizationNameChange = (e) => {
        this.setState({
            organizationName: e.target.value
        });
    }

    handleOrganizationChange = (e, d) => {
        this.setState({
            organizationId: d.value
        });
        if (this.state.organizations.filter(x => x.organizationId === d.value).length > 0) {
            this.setState({
                organizationName: this.state.organizations.filter(x => x.organizationId === d.value)[0].organizationName
            });
        }
    }

    handleGetSessionsFileClick = () => {
        const { licenseId } = this.props;
        if (licenseId !== null)
            window.location.href = `/api/Session/Csv/${licenseId}`;

    }

    handleGetLicenseUserRolesFileClick = () => {
        const { licenseId } = this.props;
        if (licenseId !== null)
            window.location.href = `/api/LicenseUserRole/Csv/${licenseId}`;

    }

    handleGetLicenseUserRolesTemplateFileClick = () => {
        window.location.href = `/api/LicenseUserRole/Csv`;

    }

    onCancel = () => {
        this.setState({ adminEmail: this.props.isAdmin ? this.state.adminEmail : this.state.originalAdminEmail, editManager: false, isValidEmail: true });
    }

    onCancelChangeOrganizationName = () => {
        this.setState(
            {
                organizationName: this.state.originalOrganizationName,
                editOrganizationName: false
            });
    }

    onCancelChangeOrganization = () => {
        this.setState(
            {
                organizationId: this.state.originalOrganizationId,
                editOrganization: false
            });
    }

    onCancelLinkOrganizationName = () => {
        this.setState(
            {
                organizationId: this.state.originalOrganizationId,
                organizationName: this.state.originalOrganizationName,
                linkOrganization: false
            });
    }

    onCancelSelectOrganization = () => {
        this.setState(
            {
                organizationId: this.state.originalOrganizationId,
                selectLicenseForLinking: false
            });
    }

    onSelectionFileSuccess = function (fileObject) {
        this.props.showLoading('IMPORT_LICENSES');
        const formData = new FormData();
        const self = this;
        formData.append('file', fileObject)
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        Axios.post(`/api/Licenses/${self.props.licenseId}/LicenseUsages/UploadCSV`, formData, config)
            .then(function () {
                self.showToastSuccess({ title: "Import Usages", message: "Usages were imported successfully" });
            })
            .catch(function (ex) {
                self.showToastError({ title: "Import Usages", message: ex.request.responseText });
                console.error(ex);
            }).then(function () {
                self.props.hideLoading('IMPORT_LICENSES');
                self.props.reloadData();
            });
    }

    submitUpdateLifetimeHandler = function () {
        const { startDate, endDate } = this.state.model;
        const self = this;
        return Axios.post(`api/Licenses/Dates`, { startDate, endDate, encryptedId: this.props.licenseId })
            .then(function () {
                self.showToastSuccess({ message: "Lifetime license was changed sucessfully." });
            })
            .catch(function (error) {

                console.error(error);
            })
            .then(function () {
                self.props.reloadData();
            })
    }


    handleMouseEnter(e) {
        e.target.style.background = '#FF8019';
        e.target.style.color = 'white';
    }

    handleMouseLeave(e) {
        e.target.style.background = 'white';
        e.target.style.color = '#FF8019';
    }

    handleAction(e) {
        switch (e.target.innerText) {
            case "Export Users":
                this.exportUsersRef.ref.click();
                break;

            case "Import Users":
                this.importUsersRef.selectFileComponent.ref.click();
                break;

            case "Rename Organization":
                this.renameOrganizationRef.ref.click();
                break;

            case "Change Organization":
                this.changeOrganizationRef.ref.click();
                break;

            case "Link Organization":
                this.linkOrganizationRef.ref.click();
                break;

            default:
                break;
        }
    }

    getFsProCloudSettingsOptions = (organizationForChanging) => {
        const canInit =
            (((!this.state.editOrganizationName && !this.state.editOrganization && !this.state.linkOrganization) &&
                (((!this.props.currentUser.data.administrator && this.state.originalOrganizationName !== null) ||
                    this.props.currentUser.data.administrator) &&
                    this.state.productId === 3)));

        if (!canInit) {
            return [];
        }

        const items = [
            ... this.state.originalOrganizationName !== null ? [{ text: "Rename Organization" }] : [],
            ... organizationForChanging.length > 1 && !this.props.currentUser.data.administrator && this.state.originalOrganizationName !== null && this.state.productId === 3 ? [{ text: "Change Organization" }] : [],
            ... this.props.currentUser.data.administrator && this.state.productId === 3 ? [{ text: "Link Organization" }] : []
        ];

        let options = [
        ];

        for (let item of items) {
            options = [...options,
            <Dropdown.Item
                style={{ color: '#FF8019' }}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onClick={this.handleAction}>
                {item.text}
            </Dropdown.Item>
            ];
        }
        return options;
    }

    showLicenseAdministratorHistory = () => {
        this.setState(
            {
                showLicenseAdministratorHistory: true
            });
    }

    toogleAIFeatures = function (isEnabled) {
        const self = this;
        Axios.put(`/api/Licenses/aifeatures/${this.props.licenseId}/${isEnabled}`)
            .then(function (response) {
                if (response) {
                    self.setState({
                        aiFeaturesEnabled: response.data.aiFeaturesEnabled,
                        aiGatewayInputTokens: response.data.aiGatewayInputTokens,
                        aiGatewayOutputTokens: response.data.aiGatewayOutputTokens,
                        aiGatewayLimitTokens: response.data.aiGatewayLimitTokens,
                    });

                    self.showToastSuccess({
                        title: "AI Features",
                        message: "AI Features were updated successfully"
                    });
                }
            })
            .catch(function (ex) {
                if (!!ex.response && ex.response.status === 400) {
                    self.showToastError({ title: "AI Features", message: ex.response.data });
                }
                else {
                    self.showToastError({
                        title: "AI Features",
                        message: "There were some error please contact to Administrators."
                    });
                }
            });
    }

    showLicenseManagemenHandler = () => {
        this.setState({
            showLicenseManagement: true,
        });
    }

    showFSProCloudSettingsHandler = () => {
        this.setState({
            showFSProCloudSettings: true,
        });
    }

    showUserDataOperationsHandler = () => {
        this.setState({
            showUserDataOperations: true,
        });
    }

    showAIFeaturesHandler = () => {
        this.setState({
            showAIFeatures: true,
        });
    }

    renderLicenseAdministratorHistoryData = (data) => {
        return [data.map((item, index) =>
            <Grid columns={16}>
                <Grid.Row textAlign='left' style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                    <Grid.Column width={8}>
                        <span>{item.dateTimeChanged}</span>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <span>{item.email}</span>
                    </Grid.Column>
                </Grid.Row>
            </Grid>)];
    }

    render() {
        const { startDate, endDate } = this.state.model;
        const classColumn = 'ui im_text_field';

        const uniqueOrganizations = [];
        const map = new Map();
        for (const item of this.state.organizations) {
            if (!map.has(item.organizationId) && item.organizationId !== null) {
                map.set(item.organizationId, true);
                uniqueOrganizations.push({
                    id: item.organizationId,
                    name: item.organizationName
                });
            }
        }

        const organizationOptions = uniqueOrganizations.map(function (o) {
            return {
                key: o.id,
                text: o.name,
                value: o.id
            };
        });

        const organizationOptionsForLinking = this.state.organizations.map(function (o) {
            return {
                key: o.organizationId,
                text: `${o.organizationName} (id: ${o.organizationId})`,
                value: o.organizationId
            };
        });

        const canUserImport = (this.props.total - this.props.current) > 0;

        const optionsImportExport = [
            { key: "import", text: "Import Users", value: "import" },
            { key: "export", text: "Export Users", value: "export" }
        ];

        const renderLabel = (option) => ({
            color: option.color,
            content: option.text
        });

        const itemStyle = {
            backgroundColor: this.state.isHover ? '#FF8019' : 'white',
            color: this.state.isHover ? 'white' : '#FF8019',
        };

        const getUserDataOperationsOptions = () => {
            const items = [
                ...canUserImport ? [{ text: "Import Users" }] : [],
                { text: "Export Users" }
            ];

            let options = [
            ];

            for (let item of items) {
                options = [...options,
                <Dropdown.Item
                    style={{ color: '#FF8019' }}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}
                    onClick={this.handleAction}>
                    {item.text}
                </Dropdown.Item>
                ];
            }
            return options;
        }

        return (
            <div>
                <Menu borderless style={{ borderWidth: '0px', boxShadow: 'none' }}>
                    <Dropdown item icon='setting' simple direction='left'>
                        <DropdownMenu>
                            {((this.state.canManage && this.props.total > 1) || this.props.isAdmin) &&
                                <DropdownItem onClick={this.showLicenseManagemenHandler}>Change License Admin</DropdownItem>}

                            {(((this.state.canManage && this.props.total > 1) || this.props.isAdmin || this.props.currentUser.data.isManager) && this.state.productId === 3) &&
                                <DropdownItem onClick={this.showFSProCloudSettingsHandler}>FS Pro Cloud Settings</DropdownItem>}

                            <DropdownItem onClick={this.showUserDataOperationsHandler}>Import/Export Users</DropdownItem>

                            {this.state.aiGatewayTokenId !== null && (this.state.canManage || this.props.isAdmin) &&
                                <DropdownItem onClick={this.showAIFeaturesHandler}>AI Features</DropdownItem>}
                        </DropdownMenu>
                    </Dropdown>
                </Menu>

                {/*License Management Modal*/}
                <Modal size="tiny" open={this.state.showLicenseManagement} onClose={() => { this.setState({ showLicenseManagement: false }); }}>
                    <Modal.Header>
                        <div>
                            <span style={{ display: 'inline-block' }}>Change License Admin</span>
                            <a href='https://support.informationmapping.com/hc/en-us/articles/11017115220892' target="_blank" style={{ display: 'inline-block', paddingLeft: '6px' }}>
                                <svg className="special__icon_large">
                                    <use xlinkHref="img/sprite/sprite.svg#svg--circle-question"></use>
                                </svg>
                            </a>
                        </div>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            {!this.state.editManager ?
                                <Grid columns={16} verticalAlign="middle">
                                    <Grid.Row style={{ paddingBottom: "0px" }}>
                                        <Grid.Column style={{ paddingLeft: "0px", paddingBottom: '0px' }} width='8'>
                                            <h3 className="headline-primary" style={{ fontSize: '16px', color: '#009edf' }}>License Administrator</h3>
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingLeft: "10px", paddingBottom: '0px' }} width='8'>
                                            <Button primary size='small'
                                                content='Change'
                                                style={{ display: "inline-block", width: '140px' }}
                                                onClick={this.toggleEditManager}></Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row style={{ paddingTop: "0px" }}>
                                        <Grid.Column style={{ paddingLeft: "0px", paddingTop: '10px' }} width='16' stretched="true">
                                            <div>
                                                <span style={{ wordWrap: "break-word" }}>{this.state.adminEmail}</span>
                                                {this.props.currentUser.data.administrator ?
                                                    <div class="iconLink" style={{ display: 'inline-block', paddingLeft: '6px' }}>
                                                        <svg className="special__icon_large" onClick={this.showLicenseAdministratorHistory}>
                                                            <use xlinkHref="img/sprite/sprite.svg#svg--history"></use>
                                                        </svg>
                                                    </div>
                                                    : null}
                                            </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                : <Grid columns={16} verticalAlign="middle">
                                    <Grid.Row>
                                        <Grid.Column style={{ paddingLeft: "0px" }} width='8' stretched="true">
                                            <input type="text" onChange={this.handleAdminEmailChange} value={this.state.adminEmail} style={{
                                                display: "inline-block",
                                                paddingTop: "5px",
                                                paddingBottom: "5px",
                                                borderColor: "#009edf",
                                                borderStyle: "solid",
                                                borderWidth: "thin"
                                            }} />
                                        </Grid.Column>
                                        <Grid.Column style={{ width: 150, paddingLeft: "0px", paddingRight: "0px" }} stretched="true" width='4'>
                                            <Button primary size='small'
                                                content='Confirm'
                                                style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}
                                                onClick={() => { this.setState({ showConfirmModal: true }) }}></Button>
                                        </Grid.Column>
                                        <Grid.Column style={{ width: 150, paddingLeft: "0px" }} stretched="true" width='4'>
                                            <Button secondary size='small'
                                                content='Cancel'
                                                style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}
                                                onClick={this.onCancel}></Button></Grid.Column>
                                    </Grid.Row>
                                </Grid>}
                        </Modal.Description>
                    </Modal.Content>
                </Modal>

                {/*FS Pro Cloud Settings Modal*/}
                <Modal size="tiny" open={this.state.showFSProCloudSettings} onClose={() => { this.setState({ showFSProCloudSettings: false }); }}>
                    <Modal.Header>
                        <div>
                            <span style={{ display: 'inline-block' }}>FS Pro Cloud Settings</span>
                            <a href='https://support.informationmapping.com/hc/en-us/articles/11017385998620' target="_blank" style={{ display: 'inline-block', paddingLeft: '6px' }}>
                                <svg className="special__icon_large">
                                    <use xlinkHref="img/sprite/sprite.svg#svg--circle-question"></use>
                                </svg>
                            </a>
                        </div>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            {/*Organization Info when Organization is linked to Cloud*/}
                            {((!this.state.editOrganizationName && !this.state.editOrganization && !this.state.linkOrganization)
                                && (((!this.props.currentUser.data.administrator && this.state.originalOrganizationName !== null) || this.props.currentUser.data.administrator) && this.state.productId === 3)) ?
                                <Grid columns={16} verticalAlign="middle">
                                    <Grid.Row style={{ paddingBottom: "0px" }}>
                                        <Grid.Column style={{ paddingLeft: '0px' }} width='8'>
                                            <h3 className="headline-primary" style={{ fontSize: '16px', color: '#009edf' }}>Organization Name</h3>
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingLeft: "10px", paddingBottom: '0px' }} width='8'>
                                            <div className='im_dropdown_button_field'>
                                                <Dropdown
                                                    button
                                                    text='Manage'>
                                                    <Dropdown.Menu>
                                                        {this.getFsProCloudSettingsOptions(organizationOptions)}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </Grid.Column>
                                        {this.state.originalOrganizationName !== null ?
                                            <Grid.Column style={{ display: "none" }} width='8' stretched='true'>
                                                <Button ref={x => this.renameOrganizationRef = x} primary size='small'
                                                    content='Rename Organization'
                                                    style={{ display: "none" }}
                                                    onClick={this.toggleChangeOrganizationName} />
                                            </Grid.Column>
                                            : null}
                                    </Grid.Row>
                                    <Grid.Row style={{ paddingTop: '10px' }}>
                                        <Grid.Column style={{ display: "inline-block", paddingLeft: '0px' }} width='8'>
                                            {this.state.originalOrganizationName !== null ?
                                                <p> {this.state.organizationName} </p>
                                                : <p> [Organization is not linked] </p>}
                                        </Grid.Column>
                                        <Grid.Column style={{ display: "none" }} width='8' stretched='true'>
                                            {!this.props.currentUser.data.administrator && this.state.originalOrganizationName !== null && this.state.productId === 3 ?
                                                <Button ref={x => this.changeOrganizationRef = x} primary size='small'
                                                    content='Change Organization'
                                                    style={{ display: "none" }}
                                                    onClick={this.toggleChangeOrganization} />
                                                : null}

                                            {this.props.currentUser.data.administrator && this.state.productId === 3 ?
                                                <Button ref={x => this.linkOrganizationRef = x} primary size='small'
                                                    content='Link Organization'
                                                    style={{ display: "none" }}
                                                    onClick={this.toggleLinkOrganization} />
                                                : null}
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                : null}

                            {/*Link organization Info when Organization is not linked to Cloud for Admin*/}
                            {this.state.linkOrganization && !this.state.selectLicenseForLinking && this.props.currentUser.data.administrator ?
                                <Grid columns='16'>
                                    <Grid.Row>
                                        <Grid.Column width='16' style={{ paddingLeft: "0px" }}>
                                            <h3 className="headline-primary" style={{ fontSize: '16px', color: '#009edf', paddingLeft: '0px' }}>Linking Organization</h3>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column style={{ paddingLeft: "0px" }} width='8' stretched="true">
                                            <input type="text" onChange={this.handleOrganizationNameChange} value={this.state.organizationName} style={{
                                                display: "inline-block", paddingTop: "5px",
                                                paddingBottom: "5px",
                                                borderColor: "#009edf",
                                                borderStyle: "solid",
                                                borderWidth: "thin"
                                            }} />
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingLeft: "0px", paddingRight: "0px" }} stretched="true" width='4'>
                                            <Button primary size='small'
                                                content='Confirm'
                                                style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}
                                                onClick={this.handleLinkOrganization}></Button>
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingLeft: "0px" }} stretched="true" width='4'>
                                            <Button secondary size='small'
                                                content='Cancel'
                                                style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}
                                                onClick={this.onCancelLinkOrganizationName}></Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                : null}

                            {/*Select organization for linking*/}
                            {this.state.selectLicenseForLinking && this.props.isAdmin ?
                                <Grid width='16'>
                                    <Grid.Row>
                                        <Grid.Column width='16' style={{ paddingLeft: "0px" }}>
                                            <h3 className="headline-primary" style={{ fontSize: '16px', color: '#009edf', paddingLeft: '0px' }}>Selecting Organization</h3>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column style={{ paddingLeft: "0px" }} width='8' stretched="true">
                                            <Dropdown
                                                fluid
                                                search
                                                className="main-page"
                                                selection
                                                name="queryOrganization"
                                                scrolling
                                                options={organizationOptionsForLinking}
                                                value={this.state.organizationId}
                                                onChange={this.handleOrganizationChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingLeft: "0px", paddingRight: "0px" }} stretched="true" width='4'>
                                            <Button primary size='small'
                                                content='Confirm'
                                                style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}
                                                onClick={() => { this.setState({ showLinkOrganizationConfirmModal: true }) }}></Button>
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingLeft: "0px" }} stretched="true" width='4'>
                                            <Button secondary size='small'
                                                content='Cancel'
                                                style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}
                                                onClick={this.onCancelSelectOrganization}></Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                : null}

                            {/*Organization Info when Organization is not linked to Cloud for not Admin*/}
                            {!this.state.editOrganizationName && !this.state.editOrganization && this.state.originalOrganizationName === null && !this.props.isAdmin && !this.state.linkOrganization && this.state.productId === 3 ?
                                <Grid width='16'>
                                    <Grid.Row>
                                        <Grid.Column style={{ paddingLeft: "0px" }} stretched="true">
                                            <p style={{ color: "#009EDF", fontWeight: 'bold', wordWrap: "break-word" }}>This license pack is not currently associated with an FS Pro Cloud
                                                organization. For automatic user access to FS Pro Cloud, please
                                                Contact support to link an existing organization or create a new one</p>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                : null}

                            {/*Change Organization Name*/}
                            {this.state.editOrganizationName ?
                                <Grid width='16'>
                                    <Grid.Row>
                                        <Grid.Column width='16' style={{ paddingLeft: "0px" }}>
                                            <h3 className="headline-primary" style={{ fontSize: '16px', color: '#009edf', paddingLeft: '0px' }}>Renaming Organization</h3>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column style={{ paddingLeft: "0px" }} width='8' stretched="true">
                                            <input type="text" onChange={this.handleOrganizationNameChange} value={this.state.organizationName} style={{
                                                display: "inline-block", paddingTop: "5px",
                                                paddingBottom: "5px",
                                                borderColor: "#009edf",
                                                borderStyle: "solid",
                                                borderWidth: "thin"
                                            }} />
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingLeft: "0px", paddingRight: "0px" }} stretched="true" width='4'>
                                            <Button primary size='small'
                                                content='Confirm'
                                                style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}
                                                onClick={() => { this.setState({ showChangeOrganizationNameConfirmModal: true }) }}></Button>
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingLeft: "0px" }} stretched="true" width='4'>
                                            <Button secondary size='small'
                                                content='Cancel'
                                                style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}
                                                onClick={this.onCancelChangeOrganizationName}></Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                : null}

                            {/*Change Organization*/}
                            {this.state.editOrganization ?
                                <Grid width='16'>
                                    <Grid.Row>
                                        <Grid.Column width='16' style={{ paddingLeft: "0px" }}>
                                            <h3 className="headline-primary" style={{ fontSize: '16px', color: '#009edf', paddingLeft: '0px' }}>Changing Organization</h3>
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Column style={{ paddingLeft: "0px" }} width='8' stretched="true">
                                            <Dropdown
                                                fluid
                                                search
                                                className="main-page"
                                                selection
                                                name="queryOrganization"
                                                scrolling
                                                options={organizationOptions}
                                                value={this.state.organizationId}
                                                onChange={this.handleOrganizationChange}
                                            />
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingLeft: "0px", paddingRight: "0px" }} stretched="true" width='4'>
                                            <Button primary size='small'
                                                content='Confirm'
                                                style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}
                                                onClick={() => { this.setState({ showChangeOrganizationConfirmModal: true }) }}></Button>
                                        </Grid.Column>
                                        <Grid.Column style={{ paddingLeft: "0px" }} stretched="true" width='4'>
                                            <Button secondary size='small'
                                                content='Cancel'
                                                style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}
                                                onClick={this.onCancelChangeOrganization}></Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                : null}
                        </Modal.Description>
                    </Modal.Content>
                </Modal>

                {/*User Data Operations Modal*/}
                <Modal size="tiny" open={this.state.showUserDataOperations} onClose={() => { this.setState({ showUserDataOperations: false }); }}>
                    <Modal.Header>
                        <div>
                            <span style={{ display: 'inline-block' }}>Import/Export Users</span>
                            <a href='https://support.informationmapping.com/hc/en-us/articles/11017211946012' target="_blank" style={{ display: 'inline-block', paddingLeft: '6px' }}>
                                <svg className="special__icon_large">
                                    <use xlinkHref="img/sprite/sprite.svg#svg--circle-question"></use>
                                </svg>
                            </a>
                        </div>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Grid width={16} verticalAlign="middle">
                                <Grid.Row style={{ paddingBottom: '0px' }}>
                                    <Grid.Column width='6'>
                                        <h3 className="headline-primary" style={{ fontSize: '16px', color: '#009edf' }}>Manage Users</h3>
                                    </Grid.Column>
                                    <Grid.Column width='10'>
                                        {canUserImport &&
                                            <div>
                                                <SelectFile ref={x => this.importUsersRef = x}
                                                    onSelectionFileSuccess={this.onSelectionFileSuccess}
                                                    title='Import Users'
                                                    showComponent={false}
                                                    commentHtml={<div>The csv file should contain columns with these headers: <br />
                                                        <table><tr>
                                                            <td style={{ border: "1px solid Gray", paddingLeft: "10px", paddingRight: "10px" }}>Email</td>
                                                            <td style={{ border: "1px solid Gray", paddingLeft: "10px", paddingRight: "10px" }}>FirstName</td>
                                                            <td style={{ border: "1px solid Gray", paddingLeft: "10px", paddingRight: "10px" }}>LastName</td>
                                                        </tr></table>
                                                        <Button primary size='small'
                                                            content='Download Import Template'
                                                            style={{ 'padding-right': '25px' }}
                                                            onClick={this.handleGetLicenseUserRolesTemplateFileClick}></Button>
                                                        <br /><br />*Only email is required</div>}
                                                    types={['csv']} onErrorHandling={this.showErrorMessage} />
                                                <div style={{ height: '10px', display: 'none' }}></div>
                                            </div>
                                        }

                                        <Button ref={x => this.exportUsersRef = x} primary size='small'
                                            content='Export Users'
                                            style={{ 'padding-right': '25px', display: "none" }}
                                            onClick={this.handleGetLicenseUserRolesFileClick} />

                                        <div className='im_dropdown_button_field'>
                                            <Dropdown
                                                button
                                                text='Choose Action'>
                                                <Dropdown.Menu>
                                                    {getUserDataOperationsOptions()}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>

                {/*AI Features Modal*/}
                <Modal size="tiny" open={this.state.showAIFeatures} onClose={() => { this.setState({ showAIFeatures: false }); }}>
                    <Modal.Header>
                        <div>
                            <span style={{ display: 'inline-block' }}>AI Features</span>
                            {/*<a href='https://support.informationmapping.com/hc/en-us/articles/11017115220892' target="_blank" style={{ display: 'inline-block', paddingLeft: '6px' }}>*/}
                            {/*    <svg className="special__icon_large">*/}
                            {/*        <use xlinkHref="img/sprite/sprite.svg#svg--circle-question"></use>*/}
                            {/*    </svg>*/}
                            {/*</a>*/}
                        </div>
                    </Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Grid columns={16}>
                                <Grid.Row>
                                    <Grid.Column width={5} verticalAlign='middle'>
                                        <h3 className="headline-primary" style={{ fontSize: '16px', color: '#009edf' }}>AI Assistant</h3>
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        {this.state.aiGatewayTokenId !== null && ((this.state.canManage && !this.state.aiFeaturesDisabledBySuperAdmin) || this.props.isAdmin)
                                            ? this.state.aiFeaturesEnabled
                                                ? <Button className="ui button primary" content='Disable' style={{ height: '38px', marginLeft: '6px' }}
                                                    onClick={() => this.toogleAIFeatures(false)} />
                                                : <Button className="ui button primary" content='Enable' style={{ height: '38px', marginLeft: '6px' }}
                                                    onClick={() => this.toogleAIFeatures(true)} isEnabled={!this.state.aiFeaturesDisabledBySuperAdmin} />
                                            : this.state.canManage && this.state.aiFeaturesDisabledBySuperAdmin
                                                ? <Button className="ui button inverse" content='Disabled' style={{ height: '38px' }} isEnabled={false} />
                                                : null}
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <span>Used tokens</span>
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        <span>{this.state.aiGatewayInputTokens + this.state.aiGatewayOutputTokens}</span>
                                    </Grid.Column>
                                    <Grid.Column width={8}/>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column width={5}>
                                        <span>Limit tokens</span>
                                    </Grid.Column>
                                    <Grid.Column width={3}>
                                        <span>{this.state.aiGatewayLimitTokens}</span>
                                    </Grid.Column>
                                    <Grid.Column width={8} />
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Column width={16}>
                                        {this.state.aiGatewayTokenId !== null && !this.props.isAdmin && this.state.canManage && this.state.aiFeaturesDisabledBySuperAdmin
                                            ? <span style={{ fontWeight: 'bold' }}>The AI features are disabled for this license pack.</span>
                                            : null}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>


                {/*<Grid columns={16}>*/}
                {/*    <Grid.Row textAlign='left'>*/}
                {/*        <Grid.Column width={5}>*/}
                {/*            */}{/*Change License Administrator*/}
                {/*            <div>*/}
                {/*                {((this.state.canManage && this.props.total > 1) || this.props.isAdmin) &&*/}
                {/*                    <div>*/}
                {/*                        <div>*/}
                {/*                            <h3 className="headline-tertiary" style={{ display: 'inline-block' }}>License Management</h3>*/}
                {/*                            <a href='https://support.informationmapping.com/hc/en-us/articles/11017115220892' target="_blank" style={{ display: 'inline-block', paddingLeft: '6px' }}>*/}
                {/*                                <svg className="special__icon_large">*/}
                {/*                                    <use xlinkHref="img/sprite/sprite.svg#svg--circle-question"></use>*/}
                {/*                                </svg>*/}
                {/*                            </a>*/}
                {/*                        </div>*/}
                {/*                        {!this.state.editManager ?*/}
                {/*                            <Grid columns={16} verticalAlign="middle">*/}
                {/*                                <Grid.Row style={{ paddingBottom: "0px" }}>*/}
                {/*                                    <Grid.Column style={{ paddingLeft: "0px", paddingBottom: '0px' }} width='8'>*/}
                {/*                                        <h3 className="headline-primary" style={{ fontSize: '16px', color: '#009edf' }}>License Administrator</h3>*/}
                {/*                                    </Grid.Column>*/}
                {/*                                    <Grid.Column style={{ paddingLeft: "10px", paddingBottom: '0px' }} width='8'>*/}
                {/*                                        <Button primary size='large'*/}
                {/*                                            content='Change'*/}
                {/*                                            style={{ display: "inline-block", width: '140px' }}*/}
                {/*                                            onClick={this.toggleEditManager}></Button>*/}
                {/*                                    </Grid.Column>*/}
                {/*                                </Grid.Row>*/}
                {/*                                <Grid.Row style={{ paddingTop: "0px" }}>*/}
                {/*                                    <Grid.Column style={{ paddingLeft: "0px", paddingTop: '10px' }} width='16' stretched="true">*/}
                {/*                                        <div>*/}
                {/*                                            <span style={{ wordWrap: "break-word" }}>{this.state.adminEmail}</span>*/}
                {/*                                            {this.props.currentUser.data.administrator ?*/}
                {/*                                                <div class="iconLink" style={{ display: 'inline-block', paddingLeft: '6px' }}>*/}
                {/*                                                    <svg className="special__icon_large" onClick={this.showLicenseAdministratorHistory}>*/}
                {/*                                                        <use xlinkHref="img/sprite/sprite.svg#svg--history"></use>*/}
                {/*                                                    </svg>*/}
                {/*                                                </div>*/}
                {/*                                                : null}*/}
                {/*                                        </div>*/}
                {/*                                    </Grid.Column>*/}
                {/*                                </Grid.Row>*/}
                {/*                            </Grid>*/}
                {/*                            : <Grid columns={16} verticalAlign="middle">*/}
                {/*                                <Grid.Row>*/}
                {/*                                    <Grid.Column style={{ paddingLeft: "0px" }} width='8' stretched="true">*/}
                {/*                                        <input type="text" onChange={this.handleAdminEmailChange} value={this.state.adminEmail} style={{*/}
                {/*                                            display: "inline-block",*/}
                {/*                                            paddingTop: "5px",*/}
                {/*                                            paddingBottom: "5px",*/}
                {/*                                            borderColor: "#009edf",*/}
                {/*                                            borderStyle: "solid",*/}
                {/*                                            borderWidth: "thin"*/}
                {/*                                        }} />*/}
                {/*                                    </Grid.Column>*/}
                {/*                                    <Grid.Column style={{ width: 150, paddingLeft: "0px", paddingRight: "0px" }} stretched="true" width='4'>*/}
                {/*                                        <Button primary size='small'*/}
                {/*                                            content='Confirm'*/}
                {/*                                            style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}*/}
                {/*                                            onClick={() => { this.setState({ showConfirmModal: true }) }}></Button>*/}
                {/*                                    </Grid.Column>*/}
                {/*                                    <Grid.Column style={{ width: 150, paddingLeft: "0px" }} stretched="true" width='4'>*/}
                {/*                                        <Button secondary size='small'*/}
                {/*                                            content='Cancel'*/}
                {/*                                            style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}*/}
                {/*                                            onClick={this.onCancel}></Button></Grid.Column>*/}
                {/*                                </Grid.Row>*/}
                {/*                            </Grid>}*/}
                {/*                    </div>}*/}
                {/*            </div>*/}
                {/*        </Grid.Column>*/}
                {/*        <Grid.Column textAlign='right' width={1}>*/}
                {/*            {this.state.productId === 3 &&*/}
                {/*                <div className="tableDivider" />}*/}
                {/*        </Grid.Column>*/}
                {/*        {((this.state.canManage && this.props.total > 1) || this.props.isAdmin || this.props.currentUser.data.isManager) &&*/}
                {/*            <Grid.Column width={5}>*/}
                {/*                {this.state.productId === 3 &&*/}
                {/*                    <div>*/}
                {/*                        <h3 className="headline-tertiary" style={{ display: 'inline-block' }}>FS Pro Cloud Settings</h3>*/}
                {/*                        <a href='https://support.informationmapping.com/hc/en-us/articles/11017385998620' target="_blank" style={{ display: 'inline-block', paddingLeft: '6px' }}>*/}
                {/*                            <svg className="special__icon_large">*/}
                {/*                                <use xlinkHref="img/sprite/sprite.svg#svg--circle-question"></use>*/}
                {/*                            </svg>*/}
                {/*                        </a>*/}
                {/*                    </div>}*/}
                {/*                */}{/*Organization Info when Organization is linked to Cloud*/}
                {/*                {((!this.state.editOrganizationName && !this.state.editOrganization && !this.state.linkOrganization)*/}
                {/*                    && (((!this.props.currentUser.data.administrator && this.state.originalOrganizationName !== null) || this.props.currentUser.data.administrator) && this.state.productId === 3)) ?*/}
                {/*                    <Grid columns={16} verticalAlign="middle">*/}
                {/*                        <Grid.Row style={{ paddingBottom: "0px" }}>*/}
                {/*                            <Grid.Column style={{ paddingLeft: '0px' }} width='8'>*/}
                {/*                                <h3 className="headline-primary" style={{ fontSize: '16px', color: '#009edf' }}>Organization Name</h3>*/}
                {/*                            </Grid.Column>*/}
                {/*                            <Grid.Column style={{ paddingLeft: "10px", paddingBottom: '0px' }} width='8'>*/}
                {/*                                <div className='im_dropdown_button_field'>*/}
                {/*                                    <Dropdown*/}
                {/*                                        button*/}
                {/*                                        text='Manage'>*/}
                {/*                                        <Dropdown.Menu>*/}
                {/*                                            {this.getFsProCloudSettingsOptions(organizationOptions)}*/}
                {/*                                        </Dropdown.Menu>*/}
                {/*                                    </Dropdown>*/}
                {/*                                </div>*/}
                {/*                            </Grid.Column>*/}
                {/*                            {this.state.originalOrganizationName !== null ?*/}
                {/*                                <Grid.Column style={{ display: "none" }} width='8' stretched='true'>*/}
                {/*                                    <Button ref={x => this.renameOrganizationRef = x} primary size='small'*/}
                {/*                                        content='Rename Organization'*/}
                {/*                                        style={{ display: "none" }}*/}
                {/*                                        onClick={this.toggleChangeOrganizationName} />*/}
                {/*                                </Grid.Column>*/}
                {/*                                : null}*/}
                {/*                        </Grid.Row>*/}
                {/*                        <Grid.Row style={{ paddingTop: '10px' }}>*/}
                {/*                            <Grid.Column style={{ display: "inline-block", paddingLeft: '0px' }} width='8'>*/}
                {/*                                {this.state.originalOrganizationName !== null ?*/}
                {/*                                    <p> {this.state.organizationName} </p>*/}
                {/*                                    : <p> [Organization is not linked] </p>}*/}
                {/*                            </Grid.Column>*/}
                {/*                            <Grid.Column style={{ display: "none" }} width='8' stretched='true'>*/}
                {/*                                {!this.props.currentUser.data.administrator && this.state.originalOrganizationName !== null && this.state.productId === 3 ?*/}
                {/*                                    <Button ref={x => this.changeOrganizationRef = x} primary size='small'*/}
                {/*                                        content='Change Organization'*/}
                {/*                                        style={{ display: "none" }}*/}
                {/*                                        onClick={this.toggleChangeOrganization} />*/}
                {/*                                    : null}*/}

                {/*                                {this.props.currentUser.data.administrator && this.state.productId === 3 ?*/}
                {/*                                    <Button ref={x => this.linkOrganizationRef = x} primary size='small'*/}
                {/*                                        content='Link Organization'*/}
                {/*                                        style={{ display: "none" }}*/}
                {/*                                        onClick={this.toggleLinkOrganization} />*/}
                {/*                                    : null}*/}
                {/*                            </Grid.Column>*/}
                {/*                        </Grid.Row>*/}
                {/*                    </Grid>*/}
                {/*                    : null}*/}

                {/*                */}{/*Link organization Info when Organization is not linked to Cloud for Admin*/}
                {/*                {this.state.linkOrganization && !this.state.selectLicenseForLinking && this.props.currentUser.data.administrator ?*/}
                {/*                    <Grid columns='16'>*/}
                {/*                        <Grid.Row>*/}
                {/*                            <Grid.Column width='16' style={{ paddingLeft: "0px" }}>*/}
                {/*                                <h3 className="headline-primary" style={{ fontSize: '16px', color: '#009edf', paddingLeft: '0px' }}>Linking Organization</h3>*/}
                {/*                            </Grid.Column>*/}
                {/*                        </Grid.Row>*/}
                {/*                        <Grid.Row>*/}
                {/*                            <Grid.Column style={{ paddingLeft: "0px" }} width='8' stretched="true">*/}
                {/*                                <input type="text" onChange={this.handleOrganizationNameChange} value={this.state.organizationName} style={{*/}
                {/*                                    display: "inline-block", paddingTop: "5px",*/}
                {/*                                    paddingBottom: "5px",*/}
                {/*                                    borderColor: "#009edf",*/}
                {/*                                    borderStyle: "solid",*/}
                {/*                                    borderWidth: "thin"*/}
                {/*                                }} />*/}
                {/*                            </Grid.Column>*/}
                {/*                            <Grid.Column style={{ paddingLeft: "0px", paddingRight: "0px" }} stretched="true" width='4'>*/}
                {/*                                <Button primary size='small'*/}
                {/*                                    content='Confirm'*/}
                {/*                                    style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}*/}
                {/*                                    onClick={this.handleLinkOrganization}></Button>*/}
                {/*                            </Grid.Column>*/}
                {/*                            <Grid.Column style={{ paddingLeft: "0px" }} stretched="true" width='4'>*/}
                {/*                                <Button secondary size='small'*/}
                {/*                                    content='Cancel'*/}
                {/*                                    style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}*/}
                {/*                                    onClick={this.onCancelLinkOrganizationName}></Button>*/}
                {/*                            </Grid.Column>*/}
                {/*                        </Grid.Row>*/}
                {/*                    </Grid>*/}
                {/*                    : null}*/}

                {/*                */}{/*Select organization for linking*/}
                {/*                {this.state.selectLicenseForLinking && this.props.isAdmin ?*/}
                {/*                    <Grid width='16'>*/}
                {/*                        <Grid.Row>*/}
                {/*                            <Grid.Column width='16' style={{ paddingLeft: "0px" }}>*/}
                {/*                                <h3 className="headline-primary" style={{ fontSize: '16px', color: '#009edf', paddingLeft: '0px' }}>Selecting Organization</h3>*/}
                {/*                            </Grid.Column>*/}
                {/*                        </Grid.Row>*/}
                {/*                        <Grid.Row>*/}
                {/*                            <Grid.Column style={{ paddingLeft: "0px" }} width='8' stretched="true">*/}
                {/*                                <Dropdown*/}
                {/*                                    fluid*/}
                {/*                                    search*/}
                {/*                                    className="main-page"*/}
                {/*                                    selection*/}
                {/*                                    name="queryOrganization"*/}
                {/*                                    scrolling*/}
                {/*                                    options={organizationOptionsForLinking}*/}
                {/*                                    value={this.state.organizationId}*/}
                {/*                                    onChange={this.handleOrganizationChange}*/}
                {/*                                />*/}
                {/*                            </Grid.Column>*/}
                {/*                            <Grid.Column style={{ paddingLeft: "0px", paddingRight: "0px" }} stretched="true" width='4'>*/}
                {/*                                <Button primary size='small'*/}
                {/*                                    content='Confirm'*/}
                {/*                                    style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}*/}
                {/*                                    onClick={() => { this.setState({ showLinkOrganizationConfirmModal: true }) }}></Button>*/}
                {/*                            </Grid.Column>*/}
                {/*                            <Grid.Column style={{ paddingLeft: "0px" }} stretched="true" width='4'>*/}
                {/*                                <Button secondary size='small'*/}
                {/*                                    content='Cancel'*/}
                {/*                                    style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}*/}
                {/*                                    onClick={this.onCancelSelectOrganization}></Button>*/}
                {/*                            </Grid.Column>*/}
                {/*                        </Grid.Row>*/}
                {/*                    </Grid>*/}
                {/*                    : null}*/}

                {/*                */}{/*Organization Info when Organization is not linked to Cloud for not Admin*/}
                {/*                {!this.state.editOrganizationName && !this.state.editOrganization && this.state.originalOrganizationName === null && !this.props.isAdmin && !this.state.linkOrganization && this.state.productId === 3 ?*/}
                {/*                    <Grid width='16'>*/}
                {/*                        <Grid.Row>*/}
                {/*                            <Grid.Column style={{ paddingLeft: "0px" }} stretched="true">*/}
                {/*                                <p style={{ color: "#009EDF", fontWeight: 'bold', wordWrap: "break-word" }}>This license pack is not currently associated with an FS Pro Cloud*/}
                {/*                                    organization. For automatic user access to FS Pro Cloud, please*/}
                {/*                                    Contact support to link an existing organization or create a new one</p>*/}
                {/*                            </Grid.Column>*/}
                {/*                        </Grid.Row>*/}
                {/*                    </Grid>*/}
                {/*                    : null}*/}

                {/*                */}{/*Change Organization Name*/}
                {/*                {this.state.editOrganizationName ?*/}
                {/*                    <Grid width='16'>*/}
                {/*                        <Grid.Row>*/}
                {/*                            <Grid.Column width='16' style={{ paddingLeft: "0px" }}>*/}
                {/*                                <h3 className="headline-primary" style={{ fontSize: '16px', color: '#009edf', paddingLeft: '0px' }}>Renaming Organization</h3>*/}
                {/*                            </Grid.Column>*/}
                {/*                        </Grid.Row>*/}
                {/*                        <Grid.Row>*/}
                {/*                            <Grid.Column style={{ paddingLeft: "0px" }} width='8' stretched="true">*/}
                {/*                                <input type="text" onChange={this.handleOrganizationNameChange} value={this.state.organizationName} style={{*/}
                {/*                                    display: "inline-block", paddingTop: "5px",*/}
                {/*                                    paddingBottom: "5px",*/}
                {/*                                    borderColor: "#009edf",*/}
                {/*                                    borderStyle: "solid",*/}
                {/*                                    borderWidth: "thin"*/}
                {/*                                }} />*/}
                {/*                            </Grid.Column>*/}
                {/*                            <Grid.Column style={{ paddingLeft: "0px", paddingRight: "0px" }} stretched="true" width='4'>*/}
                {/*                                <Button primary size='small'*/}
                {/*                                    content='Confirm'*/}
                {/*                                    style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}*/}
                {/*                                    onClick={() => { this.setState({ showChangeOrganizationNameConfirmModal: true }) }}></Button>*/}
                {/*                            </Grid.Column>*/}
                {/*                            <Grid.Column style={{ paddingLeft: "0px" }} stretched="true" width='4'>*/}
                {/*                                <Button secondary size='small'*/}
                {/*                                    content='Cancel'*/}
                {/*                                    style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}*/}
                {/*                                    onClick={this.onCancelChangeOrganizationName}></Button>*/}
                {/*                            </Grid.Column>*/}
                {/*                        </Grid.Row>*/}
                {/*                    </Grid>*/}
                {/*                    : null}*/}

                {/*                */}{/*Change Organization*/}
                {/*                {this.state.editOrganization ?*/}
                {/*                    <Grid width='16'>*/}
                {/*                        <Grid.Row>*/}
                {/*                            <Grid.Column width='16' style={{ paddingLeft: "0px" }}>*/}
                {/*                                <h3 className="headline-primary" style={{ fontSize: '16px', color: '#009edf', paddingLeft: '0px' }}>Changing Organization</h3>*/}
                {/*                            </Grid.Column>*/}
                {/*                        </Grid.Row>*/}
                {/*                        <Grid.Row>*/}
                {/*                            <Grid.Column style={{ paddingLeft: "0px" }} width='8' stretched="true">*/}
                {/*                                <Dropdown*/}
                {/*                                    fluid*/}
                {/*                                    search*/}
                {/*                                    className="main-page"*/}
                {/*                                    selection*/}
                {/*                                    name="queryOrganization"*/}
                {/*                                    scrolling*/}
                {/*                                    options={organizationOptions}*/}
                {/*                                    value={this.state.organizationId}*/}
                {/*                                    onChange={this.handleOrganizationChange}*/}
                {/*                                />*/}
                {/*                            </Grid.Column>*/}
                {/*                            <Grid.Column style={{ paddingLeft: "0px", paddingRight: "0px" }} stretched="true" width='4'>*/}
                {/*                                <Button primary size='small'*/}
                {/*                                    content='Confirm'*/}
                {/*                                    style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}*/}
                {/*                                    onClick={() => { this.setState({ showChangeOrganizationConfirmModal: true }) }}></Button>*/}
                {/*                            </Grid.Column>*/}
                {/*                            <Grid.Column style={{ paddingLeft: "0px" }} stretched="true" width='4'>*/}
                {/*                                <Button secondary size='small'*/}
                {/*                                    content='Cancel'*/}
                {/*                                    style={{ display: "inline-block", paddingLeft: '0px', paddingRight: '0px' }}*/}
                {/*                                    onClick={this.onCancelChangeOrganization}></Button>*/}
                {/*                            </Grid.Column>*/}
                {/*                        </Grid.Row>*/}
                {/*                    </Grid>*/}
                {/*                    : null}*/}
                {/*            </Grid.Column>}*/}
                {/*        <Grid.Column textAlign='right' width={1}>*/}
                {/*            {this.state.productId === 3 &&*/}
                {/*                <div className="tableDivider" />}*/}
                {/*        </Grid.Column>*/}
                {/*        <Grid.Column width={4}>*/}
                {/*            <div>*/}
                {/*                <h3 className="headline-tertiary" style={{ marginLeft: "0px", display: 'inline-block' }}>User Data Operations</h3>*/}
                {/*                <a href='https://support.informationmapping.com/hc/en-us/articles/11017211946012' target="_blank" style={{ display: 'inline-block', paddingLeft: '6px' }}>*/}
                {/*                    <svg className="special__icon_large">*/}
                {/*                        <use xlinkHref="img/sprite/sprite.svg#svg--circle-question"></use>*/}
                {/*                    </svg>*/}
                {/*                </a>*/}
                {/*            </div>*/}
                {/*            <Grid width={16} verticalAlign="middle">*/}
                {/*                <Grid.Row style={{ paddingBottom: '0px' }}>*/}
                {/*                    <Grid.Column width='6'>*/}
                {/*                        <h3 className="headline-primary" style={{ fontSize: '16px', color: '#009edf' }}>Data Transfer</h3>*/}
                {/*                    </Grid.Column>*/}
                {/*                    <Grid.Column width='10'>*/}
                {/*                        {canUserImport &&*/}
                {/*                            <div>*/}
                {/*                                <SelectFile ref={x => this.importUsersRef = x}*/}
                {/*                                    onSelectionFileSuccess={this.onSelectionFileSuccess}*/}
                {/*                                    title='Import users'*/}
                {/*                                    showComponent={false}*/}
                {/*                                    commentHtml={<div>The csv file should contain columns with these headers: <br />*/}
                {/*                                        <table><tr>*/}
                {/*                                            <td style={{ border: "1px solid Gray", paddingLeft: "10px", paddingRight: "10px" }}>Email</td>*/}
                {/*                                            <td style={{ border: "1px solid Gray", paddingLeft: "10px", paddingRight: "10px" }}>FirstName</td>*/}
                {/*                                            <td style={{ border: "1px solid Gray", paddingLeft: "10px", paddingRight: "10px" }}>LastName</td>*/}
                {/*                                        </tr></table>*/}
                {/*                                        <Button primary size='small'*/}
                {/*                                            content='Download import template'*/}
                {/*                                            style={{ 'padding-right': '25px' }}*/}
                {/*                                            onClick={this.handleGetLicenseUserRolesTemplateFileClick}></Button>*/}
                {/*                                        <br /><br />*Only email is required</div>}*/}
                {/*                                    types={['csv']} onErrorHandling={this.showErrorMessage} />*/}
                {/*                                <div style={{ height: '10px', display: 'none' }}></div>*/}
                {/*                            </div>*/}
                {/*                        }*/}

                {/*                        <Button ref={x => this.exportUsersRef = x} primary size='small'*/}
                {/*                            content='Export users'*/}
                {/*                            style={{ 'padding-right': '25px', display: "none" }}*/}
                {/*                            onClick={this.handleGetLicenseUserRolesFileClick} />*/}

                {/*                        <div className='im_dropdown_button_field'>*/}
                {/*                            <Dropdown*/}
                {/*                                button*/}
                {/*                                text='Options'>*/}
                {/*                                <Dropdown.Menu>*/}
                {/*                                    {getUserDataOperationsOptions()}*/}
                {/*                                </Dropdown.Menu>*/}
                {/*                            </Dropdown>*/}
                {/*                        </div>*/}
                {/*                    </Grid.Column>*/}
                {/*                </Grid.Row>*/}
                {/*            </Grid>*/}
                {/*        </Grid.Column>*/}
                {/*    </Grid.Row>*/}
                {/*</Grid >*/}

                <div className="two column row" style={{ paddingTop: '15px' }}>
                    {/*<div className="left floated column" style={{ display: 'flex' }}>*/}
                    {/*    <h3 className="headline-tertiary">Assigned Licenses <span className="badge--grey">{this.props.current} / {this.props.total}</span></h3>*/}
                    {/*    */}{/*Turn on / off AI Features by License Administrator or Super Admin*/}
                    {/*    {this.state.aiGatewayTokenId !== null && ((this.state.canManage && !this.state.aiFeaturesDisabledBySuperAdmin) || this.props.isAdmin)*/}
                    {/*        ? this.state.aiFeaturesEnabled*/}
                    {/*            ? <Button className="ui button primary" content='Disable AI Features' style={{ height: '38px', marginLeft: '6px' }}*/}
                    {/*                onClick={() => this.toogleAIFeatures(false)} />*/}
                    {/*            : <Button className="ui button primary" content='Enable AI Features' style={{ height: '38px', marginLeft: '6px' }}*/}
                    {/*                onClick={() => this.toogleAIFeatures(true)} isEnabled={!this.state.aiFeaturesDisabledBySuperAdmin} />*/}
                    {/*        : this.state.canManage && this.state.aiFeaturesDisabledBySuperAdmin*/}
                    {/*            ? <h3 className="headline-tertiary" style={{ fontSize: '16px', color: '#009edf', marginLeft: '6px', marginTop: '9px' }}>AI Features were disabled by Administrator</h3>*/}
                    {/*            : null}*/}
                    {/*</div>*/}
                    {/*<div className="right floated left aligned">*/}
                    {/*    {this.props.currentUser && this.props.currentUser.data && this.props.currentUser.data.administrator ?*/}
                    {/*        <div style={{ textAlign: "right" }}>*/}
                    {/*            <Form onSubmit={this.submitUpdateLifetimeHandler} >*/}

                    {/*                <Grid columns={3} >*/}
                    {/*                    <Grid.Row>*/}
                    {/*                        <Grid.Column><DateInput required className={classColumn}*/}
                    {/*                            name="startDate" label="Start Date"*/}
                    {/*                            placeholder="mm/dd/yyyy"*/}
                    {/*                            dateFormat={dateFormat}*/}
                    {/*                            closable*/}
                    {/*                            duration={200}*/}
                    {/*                            initialDate={new Date()}*/}
                    {/*                            value={startDate}*/}
                    {/*                            iconPosition="left"*/}
                    {/*                            onChange={(e, d) => this.onModelPropertyChange({ target: d })}></DateInput>*/}
                    {/*                        </Grid.Column>*/}
                    {/*                        <Grid.Column>*/}
                    {/*                            <DateInput required className={classColumn}*/}
                    {/*                                name="endDate" label="End Date"*/}
                    {/*                                placeholder="mm/dd/yyyy"*/}
                    {/*                                dateFormat={dateFormat}*/}
                    {/*                                closable*/}
                    {/*                                duration={200}*/}
                    {/*                                initialDate={new Date()}*/}
                    {/*                                value={endDate}*/}
                    {/*                                iconPosition="left"*/}
                    {/*                                onChange={(e, d) => this.onModelPropertyChange({ target: d })}></DateInput>*/}

                    {/*                        </Grid.Column>*/}
                    {/*                        <Grid.Column style={{ textAlign: "center" }}>*/}
                    {/*                            <Form.Button size="large" style={{ marginTop: "25px" }} primary content="Update"></Form.Button>*/}
                    {/*                        </Grid.Column>*/}
                    {/*                    </Grid.Row>*/}
                    {/*                </Grid>*/}

                    {/*            </Form></div> : null}</div>*/}
                </div>

                <Confirm
                    open={this.state.showConfirmModal}
                    onCancel={() => { this.setState({ showConfirmModal: false }) }}
                    onConfirm={this.changeLicenseAdmin}
                    header="Confirm License Administrator Change"
                    content={!this.props.isAdmin ?
                        "Are you sure you want to change the license administrator for this license? An email will be sent to the new administrator for confirmation." :
                        "Are you sure you want to change the license administrator for this license? This change won't require a confirmation."} />

                <Confirm
                    open={this.state.showChangeOrganizationNameConfirmModal}
                    onCancel={() => { this.setState({ showChangeOrganizationNameConfirmModal: false }) }}
                    onConfirm={this.changeOrganizationName}
                    header="Confirm Organization Name Change"
                    content={!this.props.isAdmin ?
                        "Are you sure you want to change the organization name for this license?" :
                        "Are you sure you want to change the organization name for this license? This change won't require a confirmation."} />

                <Confirm
                    open={this.state.showChangeOrganizationConfirmModal}
                    onCancel={() => { this.setState({ showChangeOrganizationConfirmModal: false }) }}
                    onConfirm={this.changeOrganization}
                    header="Confirm Organization Change"
                    content={!this.props.isAdmin ?
                        "Are you sure you want to change the organization for this license?" :
                        "Are you sure you want to change the organization for this license? This change won't require a confirmation."} />

                <Confirm
                    open={this.state.showLinkOrganizationConfirmModal}
                    onCancel={() => { this.setState({ showLinkOrganizationConfirmModal: false }) }}
                    onConfirm={this.handleLinkSelectedOrganization}
                    header="Confirm Organization Link"
                    content="Are you sure you want to link the organization for this license? This change won't require a confirmation." />

                <Modal size="tiny" open={this.state.showMailSentMsg} onClose={() => { this.setState({ showMailSentMsg: false }); this.onCancel(); }}>
                    <Modal.Header>Email Sent</Modal.Header>
                    <Modal.Content>

                        <Modal.Description>

                            <p>
                                An email has been sent to the new administrator requesting approval. <br />
                                The change will not be effective until the new administrator accepts the change.<br />
                                (Click outside this dialog to close it).
                            </p>

                        </Modal.Description>
                    </Modal.Content>
                </Modal>

                <Modal size="tiny" open={this.state.showLicenseAdministratorHistory}>
                    <Modal.Header>License Administrator Change Log</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            {/*<p>Here you can see the history of changes in license administrators.</p>*/}
                            <Grid columns={16}>
                                <Grid.Row textAlign='left' style={{ paddingBottom: '0px', paddingTop: '0px' }}>
                                    <Grid.Column width={8}>
                                        <h3>Date (mm/dd/yyyy)</h3>
                                    </Grid.Column>
                                    <Grid.Column width={8}>
                                        <h3>New License Administrator Email</h3>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            {this.renderLicenseAdministratorHistoryData(this.state.licenseAdministratorHistory)}
                            {this.state.purchaserEmail ?
                                <div style={{ paddingTop: '26px' }}>
                                    <h3>Email Purchaser:</h3>
                                    <span>{this.state.purchaserEmail}</span>
                                </div>
                                : null}
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button primary onClick={() => { this.setState({ showLicenseAdministratorHistory: false }) }}>OK</Button>
                    </Modal.Actions>
                </Modal>
            </div >
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        currentUser: state.application.currentUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        showLoading: function (caller) { return dispatch(applicationActionCreator.showLoading(caller)) },
        hideLoading: function (caller) { return dispatch(applicationActionCreator.hideLoading(caller)) },
        successMessage: function (message) { return dispatch(applicationActionCreator.notification.success(message)) },
        errorMessage: function (message) { return dispatch(applicationActionCreator.notification.error(message)) }
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(LicenseUsagesHeader)