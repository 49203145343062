import React from 'react';
import Axios from 'axios';

import matchSorter from 'match-sorter';

import _ from "underscore";

import { Button, Grid, Input, Dropdown } from 'semantic-ui-react';

import IMComponent from '../Components/IMComponent';
import applicationActionCreator from '../../redux/actions/applicationActions';
import { connect } from 'react-redux';

const wordWrapper = {
    wordWrap: "break-word"
}

class LicenseUsageGrid extends IMComponent {
    constructor(props) {
        super(props);
        this.revokeUserRef = [];
        this.editNameRef = [];
        this.saveNameRef = [];
        this.removeSessionRef = [];
        this.selectedItem = null;
        this.state = {
            ...this.state,
            queryEmail: '',
            queryFirstName: '',
            queryLastName: '',
            emailForEditing: '',
            editedFirstName: '',
            editedLastName: '',
            encryptedLicenseId: ''
        };

        this.editLicenseRow = this.editLicenseRow.bind(this);
        this.handleDataChange = this.handleDataChange.bind(this);
        this.onFocused = this.onFocused.bind(this);
        this.saveLicenseRows = this.saveLicenseRows.bind(this);
        this.onDataFocused = this.onDataFocused.bind(this);
        this.onManageAccessFocused = this.onManageAccessFocused.bind(this);
        this.clearData = this.clearData.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.getManageAccessOptions = this.getManageAccessOptions.bind(this);
        this.handleAction = this.handleAction.bind(this);
    }
    convertToUSDateFormat = (dateString) => {
        var elements = dateString.split("/");
        if (elements.length < 3) return dateString;
        return elements[1] + "/" + elements[0] + "/" + elements[2];
    }

    onFilterChanged = () => {
        const { queryEmail, queryFirstName, queryLastName } = this.state;
        let resultData = this.props.data;
        if (queryEmail)
            resultData = matchSorter(resultData, queryEmail, { keys: [x => x.accountName] });
        if (queryFirstName)
            resultData = matchSorter(resultData, queryFirstName, { keys: [x => x.firstName] });
        if (queryLastName)
            resultData = matchSorter(resultData, queryLastName, { keys: [x => x.lastName] });
        return _.sortBy(resultData, row => row.accountName);
    }

    editLicenseRow(email, firstName, lastName) {
        this.setState({
            emailForEditing: email,
            editedFirstName: firstName,
            editedLastName: lastName
        });
    }

    handleDataChange(e) {
        let value = e.target.value;
        switch (e.target.name) {
            case 'firstName':
                this.setState({
                    editedFirstName: value
                });
                break;
            case 'lastName':
                this.setState({
                    editedLastName: value
                });
                break;
            default:
                break;
        }
    }

    clearData() {
        this.setState(
            {
                emailForEditing: ''
            });
    }

    onFocused(e, item) {
        this.selectedItem = item;
        e.stopPropagation();
    }

    onDataFocused(e) {
        if (this.selectedItem === null) {
            this.clearData();
            return;
        }

        //console.log(`>>>!!!!!!!!!!!!!!!!!!!!onDataFocused ${this.selectedItem.firstName} ${this.selectedItem.lastName}`);
        this.saveLicenseRows(this.selectedItem.licenseCryptId, this.selectedItem.cryptId);
        this.selectedItem = null;
    }

    onManageAccessFocused(e) {
        e.stopPropagation();
    }

    saveLicenseRows(encryptedLicenseId, encryptedUsageId) {
        const self = this;
        this.props.showLoading('SUBMIT_FIRSTNAME_LASTNAME');
        const title = 'User Update';
        const url = '/api/Licenses/' + encryptedLicenseId + '/LicenseUsages/' + encryptedUsageId;
        let data = this.props.data;
        let model = data.find(x => x.cryptId === encryptedUsageId);
        model.firstName = this.state.editedFirstName;
        model.lastName = this.state.editedLastName;

        let promise = null;
        promise = Axios.put(url, model)
            .then(function () {
                self.clearData();
                self.showToastSuccess({ title: "User Updated", message: "First Name and Last Name were updated successfully." });
            });

        return promise
            .catch(function (ex) {
                if (!!ex.response && ex.response.status === 400) {
                    self.showToastError({ title: title, message: ex.response.data });
                }
                else {
                    self.showToastError({ title: title, message: "There were some error please contact to Administrators." })
                }
            })
            .then(function () {
                self.props.hideLoading('SUBMIT_FIRSTNAME_LASTNAME');
            });
    }

    getManageAccessOptions(item, index) {
        const items = [
            { text: "Revoke User" },
            ...item.accountName !== this.state.emailForEditing ? [{ text: "Edit Name" }] : [{ text: "Save Name" }],
            ...item.sessionCount > 0 ? [{ text: `Remove Session (${item.sessionCount})` }] : []
        ];

        let options = [
        ];

        for (let item of items) {
            options = [...options,
            <Dropdown.Item
                style={{ color: '#FF8019' }}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                onClick={this.handleAction}
                id={index}>
                {item.text}
            </Dropdown.Item>
            ];
        }
        return options;
    }

    handleMouseEnter(e) {
        e.target.style.background = '#FF8019';
        e.target.style.color = 'white';
    }

    handleMouseLeave(e) {
        e.target.style.background = 'white';
        e.target.style.color = '#FF8019';
    }

    handleAction(e) {
        const text = e.target.innerText;
        const index = e.target.id;

        const isRemoveSession = text.startsWith("Remove Session");
        const parameter = isRemoveSession ? "Remove Session" : e.target.innerText;

        switch (parameter) {
            case "Revoke User":
                this.revokeUserRef[+index].ref.click();
                break;

            case "Edit Name":
                this.editNameRef[+index].ref.click();
                break;

            case "Save Name":
                this.saveNameRef[+index].ref.click();
                break;

            case "Remove Session":
                this.removeSessionRef[+index].ref.click();
                break;

            default:
                break;
        }
    }

    renderData = (data) => {
        const { showRole } = this.props;
        if (showRole)
            return data.map((item, index) =>
                <Grid.Row key="DataShowRole" style={wordWrapper}>
                    <Grid.Column width={3} key={`${index}a-${Math.random()}`}>
                        <p>{item.accountName}</p>
                    </Grid.Column>
                    <Grid.Column width={2} key={`${index}b-${Math.random()}`}>
                        <p>{item.roleName}</p>
                    </Grid.Column>

                    <Grid.Column width={2} key={index + "c"}>
                        <p>{item.organizationName} </p>
                    </Grid.Column>

                    <Grid.Column width={2} key={index + "d"}>
                        {item.accountName !== this.state.emailForEditing
                            ? <p>{item.firstName} </p>
                            : <Input name="firstName" type="text" className="form-input" value={this.state.editedFirstName} fluid onChange={this.handleDataChange} onFocus={e => this.onFocused(e, item)} />
                        }
                    </Grid.Column>
                    <Grid.Column width={2} key={index + "e"}>
                        {item.accountName !== this.state.emailForEditing
                            ? <p>{item.lastName} </p>
                            : <Input name="lastName" type="text" className="form-input" value={this.state.editedLastName} fluid onChange={this.handleDataChange} onFocus={e => this.onFocused(e, item)} />
                        }
                    </Grid.Column>
                    {/* <Grid.Column width={2} key={index + "b"}>
                        {item.systems && item.systems.map((x, i) => ([<label>{i + 1}. {x}</label>, <br />]))}
                    </Grid.Column> */}
                    <Grid.Column width={1} key={`${index}e-${Math.random()}`}>
                        <span style={{ fontSize: "0.9em" }}>{this.props.licenseEndDate}</span>
                    </Grid.Column>
                    <Grid.Column width={1} key={`${index}f-${Math.random()}`}>
                        <span style={{ fontSize: "0.9em" }}>{item.lastUsage}</span>
                    </Grid.Column>
                    <Grid.Column width={3} key={`${index}g-${Math.random()}`} style={{ paddingLeft: '23px' }}>
                        <div className='im_dropdown_button_field' onFocus={e => this.onManageAccessFocused(e)}>
                            <Dropdown
                                button
                                text='Manage Access'>
                                <Dropdown.Menu>
                                    {this.getManageAccessOptions(item, index)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <Button ref={x => this.revokeUserRef[index] = x} id={item.cryptId} style={{ display: "none" }} color="red" size="medium" fluid className="right" index={index} id={item.cryptId} onClick={this.props.onRevokeClick}>REVOKE</Button>
                        {item.sessionCount > 0 ?
                            <Button style={{ display: "none" }} ref={x => this.removeSessionRef[index] = x} id={item.cryptId} onClick={this.props.onRemoveSessionsClick}>Remove Sessions ({item.sessionCount})</Button>
                            : ''}
                    </Grid.Column>
                    {/*<a href="#" ref={x => this.removeSessionRef = x} index={index} id={item.cryptId} style={{ color: "#4183c4", fontSize: "13px" }} onClick={this.props.onRemoveSessionsClick}>Remove Sessions ({item.sessionCount})</a>*/}
                    <Grid.Column width={0} key={`${index}h-${Math.random()}`} style={{ display: "none" }}>
                        {item.accountName !== this.state.emailForEditing
                            ? <Button ref={x => this.editNameRef[index] = x} style={{ display: "none" }} fluid primary size="medium" content="Edit" onClick={() => this.editLicenseRow(item.accountName, item.firstName, item.lastName)} />
                            : <Button ref={x => this.saveNameRef[index] = x} style={{ display: "none" }} fluid primary size="medium" content="Save" onClick={() => this.saveLicenseRows(item.licenseCryptId, item.cryptId)} />
                        }
                    </Grid.Column>
                </Grid.Row>);
        return data.map((item, index) =>
            <Grid.Row key="Data" style={wordWrapper}>
                <Grid.Column width={5} key={index + "a"}>
                    <p>{item.accountName}</p>
                </Grid.Column>
                <Grid.Column width={2} key={index + "b"}>
                    {item.accountName !== this.state.emailForEditing
                        ? <p>{item.firstName} </p>
                        : <Input name="firstName" type="text" className="form-input" value={this.state.editedFirstName} fluid onChange={this.handleDataChange} />
                    }
                </Grid.Column>
                <Grid.Column width={2} key={index + "c"}>
                    {item.accountName !== this.state.emailForEditing
                        ? <p>{item.lastName} </p>
                        : <Input name="lastName" type="text" className="form-input" value={this.state.editedLastName} fluid onChange={this.handleDataChange} />
                    }
                </Grid.Column>
                {/*<Grid.Column width={2} key={index + "b"} style={{ wordWrap: "break-word" }}>
                    {item.systems && item.systems.map((x, i) => ([<label>{i + 1}. {x}</label>, <br />]))}
                </Grid.Column>*/}
                <Grid.Column width={2} key={index + "d"}>
                    <span style={{ fontSize: "0.9em" }}>{this.props.licenseEndDate}</span>
                </Grid.Column>
                <Grid.Column width={2} key={index + "c"}>
                    <span style={{ fontSize: "0.9em" }}>{item.lastUsage}</span>
                </Grid.Column>
                <Grid.Column width={3} key={index + "d"}>
                    <div className='im_dropdown_button_field' onFocus={e => this.onManageAccessFocused(e)}>
                        <Dropdown
                            button
                            text='Manage Access'>
                            <Dropdown.Menu>
                                {this.getManageAccessOptions(item)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>

                    <Button ref={x => this.revokeUserRef[index] = x} style={{ display: "none" }} color="red" size="medium" fluid className="right" index={index} id={item.cryptId} onClick={this.props.onRevokeClick}>REVOKE</Button>
                    {item.sessionCount > 0 ?
                        <Button ref={x => this.removeSessionRef[index] = x} index={index} id={item.cryptId} style={{ color: "#4183c4", fontSize: "13px", display: "none" }} onClick={this.props.onRemoveSessionsClick}>Remove Sessions ({item.sessionCount})</Button>
                        : ''}
                </Grid.Column>
                <Grid.Column width={0} key={`${index}h-${Math.random()}`} style={{ display: "none" }}>
                    {item.accountName !== this.state.emailForEditing
                        ? <Button ref={x => this.editNameRef[index] = x} style={{ display: "none" }} fluid primary size="medium" content="Edit" onClick={() => this.editLicenseRow(item.accountName, item.firstName, item.lastName)} />
                        : <Button ref={x => this.saveNameRef[index] = x} style={{ display: "none" }} fluid primary size="medium" content="Save" onClick={() => this.saveLicenseRows(item.licenseCryptId, item.cryptId)} />
                    }
                </Grid.Column>
            </Grid.Row>);
    }

    renderHeader = () => {
        const { showRole } = this.props;
        const extraColumn = showRole ? 0 : 2;
        return [
            <Grid.Row key="header">
                <Grid.Column width={3 + extraColumn} className="panel__text--title">
                    EMAIL
                </Grid.Column>
                {showRole ? <Grid.Column width={2} className="panel__text--title">
                    ROLE
                </Grid.Column> : null}

                {showRole ? <Grid.Column width={2} className="panel__text--title">
                    ORGANIZATION
                </Grid.Column> : null}

                <Grid.Column width={2} className="panel__text--title">
                    FIRST NAME
                </Grid.Column>
                <Grid.Column width={2} className="panel__text--title">
                    LAST NAME
                </Grid.Column>
                {/*<Grid.Column width={2} className="panel__text--title">
                    SYSTEMS
        </Grid.Column>*/}
                {showRole ? <Grid.Column width={1} className="panel__text--title">
                    VALID UNTIL<br /><span style={{ fontSize: "0.7em" }}>(mm/dd/yyyy)</span>
                </Grid.Column> :
                    <Grid.Column width={2} className="panel__text--title">
                        VALID UNTIL<br /><span style={{ fontSize: "0.7em" }}>(mm/dd/yyyy)</span>
                    </Grid.Column>}

                {showRole ? <Grid.Column width={1} className="panel__text--title">
                    LAST USAGE<br /><span style={{ fontSize: "0.7em" }}>(mm/dd/yyyy)</span>
                </Grid.Column> :
                    <Grid.Column width={2} className="panel__text--title">
                        LAST USAGE<br /><span style={{ fontSize: "0.7em" }}>(mm/dd/yyyy)</span>
                    </Grid.Column>}

                <Grid.Column width={3} className="panel__text--title">
                </Grid.Column>
            </Grid.Row>];
    }
    render() {
        const data = this.onFilterChanged();
        return <Grid tabIndex={0} onFocus={this.onDataFocused}> {[this.renderHeader(), this.renderData(data)]} </Grid>;
    }
}

function mapDispatchToProps(dispatch) {
    return {
        showLoading: function (caller) { return dispatch(applicationActionCreator.showLoading(caller)) },
        hideLoading: function (caller) { return dispatch(applicationActionCreator.hideLoading(caller)) },
        successMessage: function (message) { return dispatch(applicationActionCreator.notification.success(message)) },
        errorMessage: function (message) { return dispatch(applicationActionCreator.notification.error(message)) }
    }
}
export default connect(null, mapDispatchToProps)(LicenseUsageGrid)